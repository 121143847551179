import PopupLogin from "../components/popuplogin/popuplogin";
import PopupSearch from "../components/popupsearch/popupsearch";
import PopupOrder from "../components/popuporder/popuporder";
import Popup from "../components/popup/popup";
import PopupDelete from "../components/popupdelete/popupdelete";
import PopupMail from "../components/popupmail/popupmail";
import PopupSave from "../components/popupsave/popupsave";
import utilities, {deleteCookie, getCookie, setCookie} from "./utilities";
import initAdvancedLoginPopup from "../components/popuploginadvanced/popuploginadvanced";
import PopupGainable from "../components/popupgainable/popupgainable";
import Player from "@vimeo/player";
import {Formio} from "formiojs";
import $ from 'jquery';

document.addEventListener("DOMContentLoaded", function () {
    const contactInfos = document.querySelector('.contact-infos');
    const originalParent = contactInfos?.parentNode;
    const originalNextSibling = contactInfos?.nextElementSibling;
    const breadCrumb = document.querySelector('.Breadcrumbs');
    const sideNav = document.querySelector('.SideNav');
    const targetDiv = document.getElementById('atlantic-b2b-order-book-list');
    const orderBookDetail = document.getElementById('atlantic-b2b-order-book-details');
    const orderLink = document.querySelector('.order-link.d-none');
    const loaderCarnet = document.querySelector('.loader-carnet');
    const orderResultSections = document.querySelectorAll('.order-result-section');
    const orderBook = document.querySelector('.orderBook');

    if (targetDiv) {
        targetDiv.style.width = '100%';
    }

    function handleResponse(data, elementToShow, loaderToHide) {
        if (data && Object.keys(data).length > 0) {
            if (elementToShow) {
                elementToShow.classList.remove('d-none');
                elementToShow.classList.add('d-block');
            }
            if (loaderToHide) {
                loaderToHide.classList.add('d-none');
            }
        }
    }

    function fetchDataOrderBook(url, elementToShow, loaderToHide) {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                handleResponse(data, elementToShow, loaderToHide);
                if (!elementToShow) {
                    hideLoader();
                    showContent();
                }
            })
            .catch(error => {
                console.error('Error:', error);
                if (loaderToHide) {
                    loaderToHide.classList.add('d-none');
                }
                hideLoader();

                let orderResultSectionsCurrent = document.querySelector('.order-result-section');
                if (orderResultSectionsCurrent) {
                    const orderBookTitle = document.querySelector('.orderBook-title');
                    let para = document.createElement("p");
                    para.style.backgroundColor = 'lightgrey';
                    para.style.padding = '10px';
                    let node = document.createTextNode("Une erreur est survenue lors de la récupération du carnet de commande. " +
                        "Veuillez ré-essayer plus tard. Vous pouvez également contacter le service client au 02 38 71 09 09");
                    para.appendChild(node);
                    orderBookTitle.after(para);
                }
            });
    }

    if (orderBookDetail) {
        const pathOrderBookDetail = orderBookDetail?.getAttribute('data-data-url');
        fetchDataOrderBook(pathOrderBookDetail, orderBookDetail, loaderCarnet);
    }

    function fetchDataAndDisplay() {
        fetchDataOrderBook('/b2b/dhp/orders', null, loaderCarnet);
    }

    function hideLoader() {
        if (loaderCarnet) {
            loaderCarnet.style.display = 'none';
        }
    }

    function showContent() {
        const orderResultSectionsAfterload = document.querySelectorAll('.order-result-section');
        if (orderResultSectionsAfterload) {
            orderResultSectionsAfterload.forEach(function (section) {
                section.classList.remove('d-none');
            })
        }
    }

    function moveContactInfos() {
        if (window.innerWidth <= 768) { // Mobile view
            if (sideNav) sideNav.style.display = 'none';
            contactInfos.style.display = 'flex';

            if (orderBookDetail) {
                if (loaderCarnet) loaderCarnet.setAttribute('style', 'margin-top: 0 !important;');
                orderBookDetail.insertAdjacentElement('afterend', contactInfos); // Move after targetDiv
            } else if (targetDiv) {
                loaderCarnet.setAttribute('style', 'margin-top: 0 !important;');
                targetDiv.insertAdjacentElement('afterend', contactInfos);
                if (orderLink) {
                    orderLink.classList.remove('d-none');
                }
            }
        } else { // Desktop view
            if (sideNav) sideNav.style.display = '';
            contactInfos.style.display = '';

            if (orderLink) {
                orderLink.classList.add('d-none');
            }
            // Return to the original position
            if (originalNextSibling) {
                originalParent.insertBefore(contactInfos, originalNextSibling);
            } else {
                originalParent.appendChild(contactInfos); // If it was the last child
            }
        }
    }

    if (orderBook) {
        fetchDataAndDisplay();
    }

    if (orderBook || orderBookDetail) {
        moveContactInfos();
        window.addEventListener('resize', moveContactInfos);
    }
});

document.addEventListener('DOMContentLoaded', function () {
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function () {
            const smartBannerAndroid = document.querySelector('.smartbanner.smartbanner--android');
            const headerMobile = document.querySelector('header.FullHeader');
            const btnClose = document.querySelector('.smartbanner__exit.js_smartbanner__exit');

            const isWebView = document.cookie.includes("isWebView=true");

            if (smartBannerAndroid && headerMobile) {
                if (isWebView) {
                    smartBannerAndroid.style.display = 'none';
                    headerMobile.style.top = '';
                } else {
                    headerMobile.style.top = '84px';
                    headerMobile.style.left = '6px';

                    if (btnClose) {
                        btnClose.addEventListener('click', function () {
                            smartBannerAndroid.style.display = 'none';
                            headerMobile.style.top = '';
                        });
                    }
                }
                observer.disconnect();
            }
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
    });
});

let seconds = 0;
let urlParams = new URLSearchParams(window.location.search);
let apiUrl = window.location.protocol + "//" + window.location.host;
const teaserAllButton = document.querySelectorAll('.TeaserFav.document_docga');
const teaserAllFav = document.querySelectorAll('.TeaserFav-favorite');
const formOrder = document.getElementById('formGuidePro');
let thermorMasterHeader = document.querySelectorAll('.FullHeaderTop-item--horizonLogo');
if (document.querySelector('.role_installer')) {
    var roleInstallerValue = JSON.parse(document.querySelector('.role_installer').value);
}
let maxxing;
if (typeof window.MaxxingLib === 'function'){
    maxxing = new window.MaxxingLib({
        assetBaseUrl: '/bundles/thermorprosite/images/maxxingLib/',
        uiElement: '.maxxingContainer',
        apiToken: 'mLFutja1r3S9LQsCPNkHk4O016oaixbQpX5bdeF21t1qyACid8e4PI2iLjt9gfZCgJ3AHqwwRzBP1aIC9VlDhW6Y0w1Rmv2poq0GdC3YgCkVeblaR0KgZlQN6gFn61nQ',
        urlApi: process.env.SITE_THERMOR_PRO_HOST
    });

///// maxxing - part 1

    if (document.querySelector('.user-connected') && roleInstallerValue?.enableMaxxing && roleInstallerValue?.roleInstaller) {
        thermorMasterHeader.forEach(function (item) {
            if (window.getComputedStyle(item).display !== "none") {
                let counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
                let currentStatusMaxxing = item.querySelector('.status-maxxing')?.getAttribute('data-level');
                let logoStatusMaxxing = item.querySelector('.EngagementProgramHeader--statusLogo img');
                let infoBullLogo = item.querySelector('.infoBull--logo img');
                let notifeQueteStatuttopstatus = document.querySelector('.notifeQueteStatut-top-status');
                let notifeQueteStatuttoplogo = document.querySelector('.notifeQueteStatut-top-logo');
                const splitlogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
                const srclogoStatusMaxxing = splitlogoStatusMaxxing?.slice(0, splitlogoStatusMaxxing.length - 1).join("/") + "/";
                const splitInfobulllogoStatusMaxxing = logoStatusMaxxing?.getAttribute('src').split('/')
                const srcInfobulllogoStatusMaxxing = splitInfobulllogoStatusMaxxing?.slice(0, splitInfobulllogoStatusMaxxing.length - 1).join("/") + "/";

                if (notifeQueteStatuttopstatus !== null) notifeQueteStatuttopstatus.textContent = currentStatusMaxxing
                if (infoBullLogo !== null) infoBullLogo.setAttribute('src', srcInfobulllogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + 'Logo.png');
                if (notifeQueteStatuttoplogo !== null) notifeQueteStatuttoplogo.setAttribute('src', '/bundles/thermorprosite/images/engagementProgram/' + currentStatusMaxxing?.toLowerCase() + 'Logo.png');

                logoStatusMaxxing?.setAttribute('src', srclogoStatusMaxxing + currentStatusMaxxing.toLowerCase() + '.png')
                let niveauBar = parseInt(parseInt(counterValue?.innerText.split('/')[0].trim()) / parseInt(counterValue?.innerText.split('/')[1].trim()) * 100);
                let progressLevels = document.querySelectorAll('.progress-level');
                let currentCXP = parseInt(counterValue?.innerText.split('/')[0].trim());

                progressLevels.forEach(function (progressLevel) {
                    maxxing.updateProgressBar(progressLevel,niveauBar)
                });

                document.addEventListener("DOMContentLoaded", event => {
                    const videoPlayers = document.querySelectorAll('.VideoPlayer');// an array of all videoplayers on page
                    if (videoPlayers == null || videoPlayers.length < 1) {
                        return;
                    }
                    videoPlayers.forEach(videoPlayer => {
                        initVideoPlayer(videoPlayer);
                    })
                }, {'once': true});

                function sendOrderRequest(transactionUid, orderPoppin) {
                    var tries = 2;

                    fetch('/data-order', { //first

                        method: 'POSt',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(transactionUid)
                    })
                        .then(response => {
                            if (!response.ok)
                                throw new Error();
                            return response.json()
                        })
                        .then(function (data) {
                            maxxing.apiMaxxing();

                            orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                            orderPoppin.querySelector('.masters_order_form').style.display = "none";
                            orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                            orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "block";
                        })
                        .catch(function (error) {
                            fetch('/data-order', { //second

                                method: 'POSt',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(transactionUid)
                            })
                                .then(response => {
                                    if (!response.ok)
                                        throw new Error();
                                    return response.json()
                                })
                                .then(function (data) {
                                    orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                                    orderPoppin.querySelector('.masters_order_form').style.display = "none";
                                    orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                    orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "block";
                                    maxxing.apiMaxxing();
                                })
                                .catch(function (error) {
                                    fetch('/data-order', { //third
                                        method: 'POSt',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(transactionUid)
                                    })
                                        .then(response => {
                                            if (!response.ok)
                                                throw new Error();
                                            return response.json()
                                        })
                                        .then(function (data) {
                                            orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                                            orderPoppin.querySelector('.masters_order_form').style.display = "none";
                                            orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                            orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "block";
                                            maxxing.apiMaxxing();

                                        })
                                        .catch(function (error) {
                                            //cancel maxxing transaction
                                            fetch('/cancel-maxxing-transaction', {
                                                method: 'POSt',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify(transactionUid)

                                            })
                                                .then(response => response.json())
                                                .then(function (data) {
                                                    orderPoppin.querySelector('.orderPopin-errorMsg').style.display = "block";
                                                    orderPoppin.querySelector('.orderPopin-errorMsg').innerHTML = 'Une erreur s’est produite au moment de l’enregistrement de votre commande. Nous avons annulé la transaction et vous prions de nous excuser.';
                                                    orderPoppin.querySelector('.orderPopin-formTitle').style.display = "none";
                                                    orderPoppin.querySelector('.masters_order_form').style.display = "none";
                                                    orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                                    orderPoppin.querySelector('.orderPopin-valideMsg').style.display = "none";

                                                })
                                                .catch(function (error) {
                                                    console.error('There was a problem with the fetch cancel-maxxing-transaction operation:', error);
                                                });
                                            console.error('There was a problem with the fetch operation:');
                                        });
                                    console.error('There was a problem with the fetch operation:');
                                });
                            console.error('There was a problem with the fetch operation');
                        });
                }

                let btnCoupon = document.querySelector('.coupon--btn');
                let valueCoupon = document.querySelector('.coupon--input');
                let couponInfo = document.querySelector('.coupon--info');

                if (valueCoupon) {
                    valueCoupon.addEventListener('keyup', function (e) {
                        couponInfo.style.display = 'none';
                        couponInfo.classList.remove('valide');
                        couponInfo.classList.remove('invalid');
                        valueCoupon.classList.remove('valide');
                        valueCoupon.classList.remove('invalid');
                    });

                    valueCoupon.addEventListener('input', function () {

                        let couponVal = valueCoupon.value;
                        // Transform to uppercase
                        couponVal = couponVal.toUpperCase();
                        // Remove spaces
                        couponVal = couponVal.replace(/\s/g, '');
                        // Remove line breaks
                        couponVal = couponVal.replace(/(\r\n|\n|\r)/gm, '');
                        // Update the input value
                        valueCoupon.value = couponVal;
                    })
                }

                if (btnCoupon) {
                    btnCoupon.addEventListener('click', function (e) {
                        let couponLoader = $('.coupon--loader');
                        couponLoader.show();
                        $(btnCoupon).prop("disabled", "disabled");
                        e.preventDefault();
                        let coupon = {
                            valueCoupon: valueCoupon.value
                        };
                        fetch('/engagement', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(coupon)
                        })
                            .then(response => {
                                return response.json();
                            })
                            .then((json) => {
                                let couponWin = json.couponWin;
                                let rewards = json.rewards;
                                let counters = json.counters.counter;
                                if (couponWin.valid) {
                                    couponInfo.style.display = 'inline-flex';
                                    couponInfo.classList.add('valide');
                                    couponInfo.classList.remove('invalid');
                                    valueCoupon.classList.add('valide');
                                    valueCoupon.classList.remove('invalid');
                                    couponInfo.querySelector('img').setAttribute('src', '/bundles/thermorprosite/images/profil/ic-valide.svg')
                                    couponInfo.querySelector('span').textContent = "Votre coupon a bien été pris en compte, vous gagnez " + couponWin.value + " XP";
                                    if (couponWin.ticket_msg.value != "") {
                                        let targets = couponWin.ticket_msg.value?.split(/\r?\n/);
                                        maxxing.apiTarget(targets)
                                    }
                                    let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                                    let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                                    let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                                    let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");

                                    maxxing.apiMaxxing(couponWin.value, maxxing.extractRewards(totalXpRewards, cxpRewards), maxxing.extractRewards(totalLingotsRewards, clingotRewards));

                                } else {
                                    couponInfo.style.display = 'inline-flex';
                                    couponInfo.classList.add('invalid');
                                    couponInfo.classList.remove('valide');
                                    valueCoupon.classList.add('invalid');
                                    valueCoupon.classList.remove('valide');
                                    couponInfo.querySelector('img').setAttribute('src', '/bundles/thermorprosite/images/profil/ic-erreur.svg')
                                    couponInfo.querySelector('span').textContent = "Le coupon que vous avez renseigné n'est pas valide ou a déjà été utilisé, merci de vérifier votre saisie"
                                }
                                couponLoader.hide();
                                $(btnCoupon).prop("disabled", false);
                            })
                            .catch(error => {
                                console.error(error);
                                couponLoader.hide();
                                $(btnCoupon).prop("disabled", false);
                            });
                    });
                }

                // Sélectionnez le div que vous souhaitez surveiller
                const divToObserve = document.getElementById('video-pop');

                if (divToObserve) {
                    // Créez une instance de MutationObserver
                    const observer = new MutationObserver(function (mutationsList, observer) {
                        // Parcourez les mutations détectées
                        for (let mutation of mutationsList) {
                            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                                // Si des nœuds ont été ajoutés à notre div cible
                                initVideoPlayer();
                            }
                        }
                    });

                    // Configurez l'observateur pour surveiller les ajouts de nœuds au div cible
                    const config = {childList: true, subtree: true};
                    observer.observe(divToObserve, config);
                }

                // Pour arrêter d'observer plus tard (par exemple, si vous n'en avez plus besoin)
                // observer.disconnect();
                function initVideoPlayer() {
                    let childVideo = document.querySelector('.BlockVideo-mainiframe') // defined as a let, can either be a div or an iframe -- redefined if elem changes
                    let videoUrl = childVideo.getAttribute('src');

                    videoUrl = videoUrl.replace('?autoplay=1', "");
                    const parts = videoUrl.split('/');
                    const videoId = decodeURIComponent(parts[parts.length - 1]);
                    let player = new Player(childVideo);
                    let dataToSend = {
                        videoId: videoId
                    };
                    let fetchCompleted = false;

                    fetch('/infos-video', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(dataToSend)
                    })
                        .then(response => response.json())
                        .then(data => {
                            let timerVideo = data.maxxingReadingPercentage || 90;
                            player.on("timeupdate", function () {
                                player.getCurrentTime().then(function (currentTime) {
                                    player.getDuration().then(function (totalDuration) {
                                        if (!isNaN(currentTime) && !isNaN(totalDuration)) {
                                            var widthPercentage = (currentTime / totalDuration) * 100;
                                            if (widthPercentage > timerVideo && !fetchCompleted) {
                                                fetchCompleted = true;
                                                maxxing.sendPostRequest(dataToSend)
                                            }
                                        } else {
                                            console.warn("Invalid values for current time or total duration.");
                                        }
                                    });
                                });
                            });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }

                document.querySelectorAll('.download_file').forEach(function (el) {
                    el.addEventListener('click', function () {
                        const namePdf = el.getElementsByClassName("TeaserFav-notice");
                        if (namePdf.item(0)) {
                            let dataToSend = {
                                namePdf: namePdf[0].innerText
                            };
                            maxxing.sendPostRequest(dataToSend);
                        }
                    });
                });

                const liseusePdf = document.getElementById('liseuse-pdf-container');
                if (liseusePdf) {
                    let documentId = liseusePdf.getAttribute('document_id');
                    let dataToSend = {
                        documentId: documentId
                    };
                    maxxing.sendPostRequest(dataToSend);
                }
                document.addEventListener('DOMContentLoaded', function () {
                    setTimeout(() => {
                        const buttonDown = document.getElementsByClassName('reading__download');
                        if (buttonDown.item(0)) {
                            buttonDown.item(0).addEventListener('click', function () {
                                const currentUrl = window.location.href;
                                const parts = currentUrl.split('/');
                                const namePdf = decodeURIComponent(parts[parts.length - 1]);
                                let dataToSend = {
                                    namePdf: namePdf
                                };
                                maxxing.sendPostRequest(dataToSend);
                            });
                        }
                    }, 10000)

                });
                if (formOrder !== null) {
                    if (formOrder.dataset.formResult === 'success') {
                        let dataToSend = {
                            order: 'COMGUI'
                        };
                        maxxing.sendPostRequest(dataToSend);
                    }
                }
                const saveDimSubmit = document.getElementById("saveDim-submit");
                if (saveDimSubmit) {
                    saveDimSubmit.addEventListener("click", function (e) {
                        e.preventDefault();
                        let successSave = document.querySelector('.successSave');
                        if (successSave) {
                            let breadcrumb = document.querySelector('.Breadcrumbs-list');
                            const liElements = breadcrumb.querySelectorAll("li");
                            const pacs = {
                                'piscine': 'piscine',
                                'air': 'air',
                                'chauffe': 'chauffe'
                            };
                            for (const key in pacs) {
                                liElements.forEach(function (li) {
                                    const liText = li.textContent.toLowerCase();

                                    if (liText.includes(key)) {
                                        let dataToSend = {
                                            simulHome: pacs[key]
                                        };
                                        e.preventDefault();

                                        maxxing.sendPostRequest(dataToSend);
                                    }
                                });
                            }
                        }
                    });
                }
                let pathnamePage = window.location.pathname;
                let messageScroll = false;
                let isStillOnPage = false;
                let timeOut = 0;
                let intervalTimeOut = 0;
                let isValorisedData = document.querySelector('.is_valorised');
                if (isValorisedData) {
                    let dataParse = JSON.parse(isValorisedData.value);
                    let viewTypeContent = dataParse.viewTypeContent;
                    let isValorised = dataParse.isValorised;
                    let timerPage = dataParse?.timerPage || '';
                    let percentageScroll = dataParse?.percentageScroll || '';

                    document.addEventListener('DOMContentLoaded', function () {
                        if (viewTypeContent === 'full' && isValorised) {
                            window.addEventListener('scroll', function () {
                                const currentScrollPosition = window.scrollY || window.pageYOffset;
                                const documentHeight = document.documentElement.scrollHeight;
                                const scrollThreshold = percentageScroll ? (+percentageScroll / 100) : 0.5;
                                const scrollPositionThreshold = documentHeight * scrollThreshold;

                                if (currentScrollPosition > scrollPositionThreshold && getCookie(pathnamePage) !== "true") {
                                    setCookie(pathnamePage, true, false, false, 30);
                                    messageScroll = true;
                                }
                            });

                            timeOut = setTimeout(() => {
                                isStillOnPage = true;
                            }, timerPage ? (+timerPage * 1000) : 10000);

                            intervalTimeOut = setInterval(() => {
                                if (messageScroll && isStillOnPage) {
                                    let dataToSend = {
                                        pathnamePage: pathnamePage
                                    };
                                    maxxing.sendPostRequest(dataToSend);
                                    timeOut = clearTimeout(timeOut)
                                    messageScroll = false;
                                    isStillOnPage = false;
                                    clearInterval(intervalTimeOut);
                                }
                            }, 1)
                        }

                    });
                }


                var formElement = document.getElementById('masters_decla_tiers');
                if (formElement) {
                    Formio.createForm(formElement, 'https://vfhdbhdalpkaqwk.form.io/themastersdeclatiers').then(form => {
                        if (form) {
                            var hiddenInput = document.getElementById("input-user").value;
                            var userData = JSON.parse(hiddenInput);
                            var account = userData.account;
                            var contact = userData.contact;
                            var accountExternalIdForm = document.querySelector('.formio-component-proAccountExternalId');
                            if (accountExternalIdForm) {
                                accountExternalIdForm.style.display = "none";
                            }
                            var contactExternalIdForm = document.querySelector('.formio-component-proContactExternalId');
                            if (contactExternalIdForm) {
                                contactExternalIdForm.style.display = "none";
                            }
                            form.submission = {
                                data: {
                                    civilite: contact.titleLabel ?? '',
                                    nom: contact.lastName ?? '',
                                    prenom: contact.firstName ?? '',
                                    email: contact.email,
                                    proContactExternalId: contact.externalId ?? '',
                                    proAccountExternalId: account['externalId'] ?? ''
                                }
                            };
                            form.on('render', function () {
                                form.getComponent('civilite').setValue(contact.titleLabel ?? '');
                                form.getComponent('nom').setValue(contact.lastName ?? '');
                                form.getComponent('prenom').setValue(contact.firstName ?? '');
                                form.getComponent('email').setValue(contact.email);
                                form.getComponent('proContactExternalId').setValue(contact.externalId ?? '');
                                form.getComponent('proAccountExternalId').setValue(account['externalId'] ?? '');
                            });

                            form.render();
                            form.on('submit', function (submission) {
                            });
                            form.on('submitDone', function (submission) {
                                let equipment = document.querySelector('.equipment_path').value;
                                let equipmentPath = JSON.parse(equipment);
                                var currentUrl = window.location.href;
                                window.location.href = currentUrl.replace('/masters-tiers', equipmentPath);
                            });
                        }
                    });
                }
                const cardProduct = document.querySelectorAll('.masters_order_form');
                var transactionId = null;
                cardProduct.forEach(function (item) {
                    Formio.createForm(
                        document.getElementById(item.getAttribute('id')),
                        'https://vfhdbhdalpkaqwk.form.io/themastersorder',
                        {
                            hooks: {
                                beforeSubmit: (submission, next) => {
                                    let orderVisible = document.querySelector('.orderPopin.is-visible');
                                    let loaderHtml = '<img class="loader-thermor" src="/bundles/thermorprosite/images/animation/thermor-animation-chargement.svg" alt="chargement"/>';
                                    $('.orderPopin.is-visible .orderPopin-actions').prepend(loaderHtml);
                                    $('.orderPopin.is-visible .orderPopin-valide').parent().css('display', 'none');
                                    $('.orderPopin.is-visible .orderPopin-cancel').parent().css('display', 'none');
                                    var hiddenInput = orderVisible.querySelector(".input-product").value;

                                    var productData = JSON.parse(hiddenInput);
                                    var dataToSend = {
                                        productId: productData.productId,
                                        lingotProduct: productData.lingotProduct
                                    };
                                    fetch('/info-doc', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization-Maxxing': `Token ${maxxing.apiToken}`
                                        },
                                        body: JSON.stringify(dataToSend)
                                    })
                                        .then(response => response.json())
                                        .then(function (data) {
                                            let submitErrors = null;
                                            if (data.error) {
                                                submitErrors = [];
                                                submitErrors.push({
                                                    message: data.error
                                                });
                                                orderVisible.querySelector('.orderPopin-errorMsg').style.display = "block";
                                                orderVisible.querySelector('.orderPopin-errorMsg').innerHTML = 'Détail de l’erreur: ' + data.error;
                                                orderVisible.querySelector('.orderPopin-formTitle').style.display = "none";
                                                orderVisible.querySelector('.masters_order_form').style.display = "none";
                                                orderVisible.querySelector('.orderPopin-cancel').style.display = "none";
                                            }
                                            transactionId = data.webTransaction_request_response_code.transaction_uid.value;
                                            next(submitErrors);
                                        })
                                        .catch(function (error) {
                                            console.error('There was a problem with the fetch operation:', error);
                                        });

                                }
                            }
                        }
                    ).then(function (form) {
                        if (form) {
                            const orderProductBtn = document.querySelectorAll('.ProgramCardProduct .ProgramCardProduct-order');
                            if (orderProductBtn) {

                                orderProductBtn.forEach(function (item) {
                                    const orderPoppin = item.closest('.ProgramCardProduct').nextElementSibling;
                                    const cancelBtn = orderPoppin.querySelectorAll('.cancel-order');
                                    item.addEventListener('click', function () {
                                        orderPoppin.classList.add('is-visible');
                                        var hiddenInput = orderPoppin.querySelector(".input-product").value;

                                        var productData = JSON.parse(hiddenInput);
                                        var dataToSend = {
                                            productId: productData.productId,
                                            lingotProduct: productData.lingotProduct
                                        };
                                        /************************************************* v* */
                                        var productValidate = orderPoppin.querySelector(".validate-product").value;
                                        var productValidateData = JSON.parse(productValidate);
                                        let account = productValidateData.account;
                                        let contact = productValidateData.contact;
                                        let orderData = productValidateData.orderData;
                                        let orderTiers = productValidateData.orderTiers;
                                        form.submission = {
                                            data: {
                                                nom: orderData.nom ?? contact.lastName,
                                                prenom: orderData.prenom ?? contact.firstName,
                                                email: orderData.email ?? contact.email,
                                                adresse: orderData.adresse ?? account.address['addressLine'],
                                                ville: orderData.ville ?? account.address['city'],
                                                codePostal: orderData.codePostal ?? account.address['postalCode'],
                                                raisonSociale: orderData.raisonSociale ?? account.companyName,
                                                proAccountExternalId: account.externalId || '',
                                                productId: productValidateData.productId || '',
                                                siret: orderData.siret ?? account.siret,
                                                proContactExternalId: contact.externalId || '',
                                                pays: orderData.pays ?? account.address['countryCode'],
                                                numeroTelephone: orderData.numeroTelephone ?? contact.mobilePhoneNumber,
                                                salarie: orderTiers.etesVousSalarie ?? '',
                                                dateNaissance: orderTiers.dateDeNaissance ?? '',
                                                numeroDeSecuriteSocial: orderTiers.numeroDeSecuriteSociale13Chiffres ?? '',
                                                idJoueurMaxxing: productValidateData.accountMaxxingId ?? '',
                                                titre: productValidateData.productName ?? '',
                                                type: productValidateData.categoryProduct ?? '',
                                                prestataire: productValidateData.prestataire ?? '',
                                                price: productValidateData.price ?? '',
                                                lingotProduct: productValidateData.lingotProduct ?? '',
                                                clingot: productValidateData.cLingot ?? ''
                                            }
                                        };
                                        form.on('render', function () {
                                            form.getComponent('nom').setValue(orderData.nom ?? contact.lastName);
                                            form.getComponent('prenom').setValue(orderData.prenom ?? contact.firstName);
                                            form.getComponent('email').setValue(orderData.email ?? contact.email);
                                            form.getComponent('adresse').setValue(orderData.adresse ?? account.address['addressLine']);
                                            form.getComponent('ville').setValue(orderData.ville ?? account.address['city']);
                                            form.getComponent('raisonSociale').setValue(orderData.raisonSociale ?? account.companyName);
                                            form.getComponent('proAccountExternalId').setValue(account.externalId || '');
                                            form.getComponent('productId').setValue(productValidateData.productId || '');
                                            form.getComponent('siret').setValue(orderData.siret ?? account.siret);
                                            form.getComponent('proContactExternalId').setValue(contact.externalId || '');
                                            form.getComponent('pays').setValue(orderData.pays ?? account.address['countryCode']);
                                            form.getComponent('numeroTelephone').setValue(orderData.numeroTelephone ?? contact.mobilePhoneNumber);
                                            form.getComponent('salarie').setValue(orderTiers.etesVousSalarie ?? '');
                                            form.getComponent('dateNaissance').setValue(orderTiers.dateDeNaissance ?? '');
                                            form.getComponent('numeroDeSecuriteSocial').setValue(orderTiers.numeroDeSecuriteSociale13Chiffres ?? '');
                                            form.getComponent('idJoueurMaxxing').setValue(productValidateData.accountMaxxingId ?? '');
                                            form.getComponent('titre').setValue(productValidateData.productName ?? '');
                                            form.getComponent('type').setValue(productValidateData.categoryProduct ?? '');
                                            form.getComponent('prestataire').setValue(productValidateData.prestataire ?? '');
                                            form.getComponent('price').setValue(productValidateData.price ?? '');
                                            form.getComponent('lingotProduct').setValue(productValidateData.lingotProduct ?? '');
                                            form.getComponent('clingot').setValue(productValidateData.cLingot ?? '');
                                        });

                                        form.render();
                                        form.off('submit');
                                        form.once('submit', function (submission) {
                                            if (transactionId) {
                                                submission.data.transactionId = transactionId;
                                                form.getComponent('transactionId').setValue(transactionId);
                                                let tries = 0;
                                                var transactionUid = {
                                                    formOrder: submission,
                                                    dataWeb: transactionId
                                                };
                                                sendOrderRequest(transactionUid, orderPoppin);
                                            }
                                        });
                                        /********************************** */
                                        form.on('submitDone', function (submission) {
                                            cancelBtn.forEach(function (item) {
                                                item.addEventListener('click', function () {
                                                    window.location.href = productData.equipmentPath;
                                                    orderPoppin.classList.remove('is-visible');
                                                })
                                            })
                                        });
                                    })
                                    cancelBtn.forEach(function (item) {
                                        item.addEventListener('click', function () {
                                            orderPoppin.classList.remove('is-visible');
                                        })
                                    })
                                })
                            }
                        }
                    });
                })
                var currentUrl = window.location.href;
                var url = new URL(currentUrl);
                var productIdParam = url.searchParams.get("productId");
                if (productIdParam) {
                    let popin = document.getElementById(`masters_order_form_${productIdParam}`);
                    Formio.createForm(popin, 'https://vfhdbhdalpkaqwk.form.io/themastersorder').then(function (form) {
                        if (form) {
                            const orderPoppin = popin.closest('.orderPopin');
                            const cancelBtn = popin?.closest('.orderPopin').querySelectorAll('.cancel-order');
                            popin?.closest('.orderPopin').classList.add('is-visible');
                            var hiddenInput = orderPoppin.querySelector(".input-product").value;
                            var productData = JSON.parse(hiddenInput);
                            var currentUrl = window.location.href;
                            var url = new URL(currentUrl);
                            var productIdParam = url.searchParams.get("productId");
                            var dataToSend = {
                                productId: productIdParam,
                                lingotProduct: productData.lingotProduct
                            };

                            /************************************************* v* */
                            var productValidate = orderPoppin.querySelector(".validate-product").value;
                            var productValidateData = JSON.parse(productValidate);
                            let account = productValidateData.account;
                            let contact = productValidateData.contact;
                            let orderData = productValidateData.orderData;
                            let orderTiers = productValidateData.orderTiers;
                            var currentUrl = window.location.href;
                            var url = new URL(currentUrl);
                            var productIdParam = url.searchParams.get("productId");
                            form.submission = {
                                data: {
                                    nom: orderData.nom ?? contact.lastName,
                                    prenom: orderData.prenom ?? contact.firstName,
                                    email: orderData.email ?? contact.email,
                                    adresse: orderData.adresse ?? account.address['addressLine'],
                                    ville: orderData.ville ?? account.address['city'],
                                    codePostal: orderData.codePostal ?? account.address['postalCode'],
                                    raisonSociale: orderData.raisonSociale ?? account.companyName,
                                    proAccountExternalId: account.externalId || '',
                                    productId: productIdParam ?? '',
                                    siret: orderData.siret ?? account.siret,
                                    proContactExternalId: contact.externalId || '',
                                    pays: orderData.pays ?? account.address['countryCode'],
                                    numeroTelephone: orderData.numeroTelephone ?? contact.mobilePhoneNumber,
                                    salarie: orderTiers.etesVousSalarie ?? '',
                                    dateNaissance: orderTiers.dateDeNaissance ?? '',
                                    numeroDeSecuriteSocial: orderTiers.numeroDeSecuriteSociale13Chiffres ?? '',
                                    idJoueurMaxxing: productValidateData.accountMaxxingId ?? '',
                                    titre: productValidateData.productName ?? '',
                                    type: productValidateData.categoryProduct ?? '',
                                    prestataire: productValidateData.prestataire ?? '',
                                    price: productValidateData.price ?? '',
                                    lingotProduct: productValidateData.lingotProduct ?? '',
                                    clingot: productValidateData.cLingot ?? ''
                                }
                            };
                            form.on('render', function () {
                                form.getComponent('nom').setValue(orderData.nom ?? contact.lastName);
                                form.getComponent('prenom').setValue(orderData.prenom ?? contact.firstName);
                                form.getComponent('email').setValue(orderData.email ?? contact.email);
                                form.getComponent('adresse').setValue(orderData.adresse ?? account.address['addressLine']);
                                form.getComponent('ville').setValue(orderData.ville ?? account.address['city']);
                                form.getComponent('raisonSociale').setValue(orderData.raisonSociale ?? account.companyName);
                                form.getComponent('proAccountExternalId').setValue(account.externalId || '');
                                form.getComponent('productId').setValue(productIdParam ?? '');
                                form.getComponent('siret').setValue(orderData.siret ?? account.siret);
                                form.getComponent('proContactExternalId').setValue(contact.externalId || '');
                                form.getComponent('pays').setValue(orderData.pays ?? account.address['countryCode']);
                                form.getComponent('numeroTelephone').setValue(orderData.numeroTelephone ?? contact.mobilePhoneNumber);
                                form.getComponent('salarie').setValue(orderTiers.etesVousSalarie ?? '');
                                form.getComponent('dateNaissance').setValue(orderTiers.dateDeNaissance ?? '');
                                form.getComponent('numeroDeSecuriteSocial').setValue(orderTiers.numeroDeSecuriteSociale13Chiffres ?? '');
                                form.getComponent('idJoueurMaxxing').setValue(productValidateData.accountMaxxingId ?? '');
                                form.getComponent('titre').setValue(productValidateData.productName ?? '');
                                form.getComponent('type').setValue(productValidateData.categoryProduct ?? '');
                                form.getComponent('prestataire').setValue(productValidateData.prestataire ?? '');
                                form.getComponent('price').setValue(productValidateData.price ?? '');
                                form.getComponent('lingotProduct').setValue(productValidateData.lingotProduct ?? '');
                                form.getComponent('clingot').setValue(productValidateData.cLingot ?? '');
                            });
                            form.render();
                            let popinProduct = popin?.closest('.orderPopin');
                            /********************************** */
                            form.on('submit', function (submission) {
                                fetch('/info-doc', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization-Maxxing': `Token ${maxxing.apiToken}`
                                    },
                                    body: JSON.stringify(dataToSend)
                                })
                                    .then(response => response.json())
                                    .then(function (data) {
                                        let dataWeb = data.webTransaction_request_response_code.transaction_uid.value;
                                        submission.data.transactionId = dataWeb;
                                        form.getComponent('transactionId').setValue(dataWeb);
                                        var transactionUid = {
                                            formOrder: submission,
                                            dataWeb: dataWeb
                                        };
                                        sendOrderRequest(transactionUid, popinProduct);
                                    })
                                    .catch(function (error) {
                                        console.error('There was a problem with the fetch operation:', error);
                                    });
                            });

                            form.on('submitDone', function (submission) {
                                popinProduct.querySelector('.orderPopin-formTitle').style.display = "none";
                                popinProduct.querySelector('.masters_order_form').style.display = "none";
                                orderPoppin.querySelector('.orderPopin-cancel').style.display = "none";
                                popinProduct.querySelector('.orderPopin-valideMsg').style.display = "block";
                            });

                            cancelBtn.forEach(function (item) {
                                item.addEventListener('click', function () {
                                    if (productIdParam) {
                                        var currentUrl = window.location.href;
                                        var urlParams = new URLSearchParams(currentUrl.search);
                                        urlParams.delete('productId');
                                        var newUrl = currentUrl.split('?')[0];
                                        if (urlParams.toString() !== '') {
                                            newUrl += '?' + urlParams.toString();
                                        }
                                        window.location.href = newUrl;
                                    }
                                    popin?.closest('.orderPopin').classList.remove('is-visible');
                                })
                            })
                        }
                    });
                }
            }
        })
    }
}


///// end  maxxing - part 1


window.onload = () => {
    setInterval(startTimer, 1000);

    function startTimer() {
        ++seconds;
    }

    const recommendationChangeDown = document.querySelector('.recommendation-change-down');
    if (recommendationChangeDown) recommendationChangeDown.addEventListener("click", changeRecommendationDown);
    const recommendationChangeUp = document.querySelector('.recommendation-change-up');
    if (recommendationChangeUp) recommendationChangeUp.addEventListener("click", changeRecommendationUp);
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    if (recommendationChangeConsole) recommendationChangeConsole.addEventListener("click", changeRecommendationConsole);

    const isPacAirAirTertiaire = document.querySelector('.pac-air-air-tertiaire');
    const isPacAirAirIndiv = document.querySelector('.pac-air-air-indiv');
    const isPacChauffeEauNouvelleInstallation = document.querySelector('.pac-chauffe-eau-nouvelle-installation');
    const isPacChauffeEauRemplace = document.querySelector('.pac-chauffe-eau-remplace');
    const isPacChauffeEauReference = document.querySelector('.pac-chauffe-eau-reference');
    const isPacPiscine = document.querySelector('.pac-piscine');

    if (urlParams.get('diplayMsg') !== null && urlParams.get('id') !== null) {
        let paramUrl = '?id=' + urlParams.get('id');
        if (urlParams.get('reference') !== null) {
            paramUrl += '&reference=' + urlParams.get('reference');
        }
        window.history.replaceState({}, document.title, window.location.href.split('?')[0] + paramUrl);
        document.querySelector(".successSave").style.display = "flex";
        setTimeout(() => {
            document.querySelector(".successSave").style.display = "none";
        }, 3000);
        document.querySelector('.Save-Dim').disabled = true;
    }

    const saveDim = document.querySelector('.Save-Dim');
    if (isPacAirAirIndiv && saveDim &&
        getCookie("pac_air_air_step1") !== null &&
        getCookie("pac_air_air_step2") !== null &&
        !JSON.parse(getCookie("pac_air_air_step1"))['canBeSaved'] &&
        !JSON.parse(getCookie("pac_air_air_step2"))['canBeSaved']
    ) {
        saveDim.disabled = true;
    } else if (isPacAirAirTertiaire && saveDim && getCookie("pac_air_tertiaire_step1") !== null &&
        !JSON.parse(getCookie("pac_air_tertiaire_step1"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacChauffeEauNouvelleInstallation && saveDim && getCookie("new_installation") !== null &&
        !JSON.parse(getCookie("new_installation"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacChauffeEauRemplace && saveDim && getCookie("replace_chauffe-eau") !== null &&
        !JSON.parse(getCookie("replace_chauffe-eau"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacChauffeEauReference && saveDim && getCookie("reference_chauffe_eau") !== null &&
        !JSON.parse(getCookie("reference_chauffe_eau"))['canBeSaved']) {
        saveDim.disabled = true;
    } else if (isPacPiscine && saveDim && getCookie("pac_piscine_step1") !== null &&
        !JSON.parse(getCookie("pac_piscine_step1"))['canBeSaved']) {
        saveDim.disabled = true;
    }
}

function getTimestampInSeconds() {
    return Math.floor(Date.now() / 1000)
}

// master logo position

const disconnectedMasterLogo = document.querySelector('.FullHeaderTop-item--horizonLogo.desactivated-account');
const connectedAccount = document.querySelector('.FullHeaderTop-link--withIcon');

if (disconnectedMasterLogo) {
    let rightOffset = connectedAccount.offsetWidth + document.querySelector('.FullHeaderTop-link--sites').offsetHeight;
    disconnectedMasterLogo.style.right = rightOffset + 'px';
}

let isPacAirAir = document.querySelector('#BlockProjectDim--wrapper.BlockProjectDimAirAir--wrapper');
let isPoolPac = document.querySelector('#BlockProjectDim--wrapper.BlockProjectDimPool--wrapper');
let isPacChauffeEau = document.querySelector('#BlockProjectChauffe-eau--wrapper');

if (isPacAirAir) {

    document.querySelector('#BlockProjectDim--wrapper').querySelectorAll('.BlockProjectDim').forEach(function (item) {
        const editLink = item.querySelector('.BlockProjectDim--actions .edit');
        const syntheseLink = item.querySelector('.BlockProjectDim--response .synthese');
        const responseLink = item.querySelector('.BlockProjectDim--response .response');

        editLink.addEventListener('click', function () {
            const idItem = editLink.dataset.id;
            const linkItem = editLink.dataset.link;
            const type = editLink.dataset.type;

            if (type == "appartement") {
                updateCookie('Individuel', idItem, ["pac_air_air_step1", "pac_air_air_step2"], '/pac-air-air-get-project-by-id/', linkItem);
            } else {
                updateCookie('Tertiaire', idItem, ["pac_air_tertiaire_step1"], '/pac-air-air-get-project-by-id/', linkItem);
            }
        })

        syntheseLink.addEventListener('click', function () {
            const idItem = syntheseLink.dataset.id;
            const linkItem = syntheseLink.dataset.link;
            const type = syntheseLink.dataset.type;

            if (type == "appartement") {
                updateCookie('Individuel', idItem, ["pac_air_air_step1", "pac_air_air_step2"], '/pac-air-air-get-project-by-id/', linkItem);
            } else {
                updateCookie('Tertiaire', idItem, ["pac_air_tertiaire_step1"], '/pac-air-air-get-project-by-id/', linkItem);
            }
        })

        if (responseLink) {
            responseLink.addEventListener('click', function () {
                const idItem = responseLink.dataset.id;
                const linkItem = responseLink.dataset.link;
                const type = responseLink.dataset.type;

                if (type == "appartement") {
                    updateCookie('Individuel', idItem, ["pac_air_air_step1", "pac_air_air_step2"], '/pac-air-air-get-project-by-id/', linkItem);
                } else {
                    updateCookie('Tertiaire', idItem, ["pac_air_tertiaire_step1"], '/pac-air-air-get-project-by-id/', linkItem);
                }
            })
        }
    })
} else if (isPoolPac) {

    document.querySelector('#BlockProjectDim--wrapper').querySelectorAll('.sizing-pool').forEach(function (item) {
        const linkId = item.querySelector('.BlockProjectDim--actions .edit');
        const responseLink = item.querySelector('.BlockProjectDim--response .response');

        linkId.addEventListener('click', function () {
            const idItem = linkId.dataset.id;
            const linkItem = linkId.dataset.link;
            updateCookie('Piscine', idItem, ["pac_piscine_step1"], '/pac-pool-get-project-by-id/', linkItem);
        })

        responseLink.addEventListener('click', function () {
            const idItem = responseLink.dataset.id;
            const linkItem = responseLink.dataset.link;
            updateCookie('Piscine', idItem, ["pac_piscine_step1"], '/pac-pool-get-project-by-id/', linkItem);
        })
    })
} else if (isPacChauffeEau) {
    document.querySelector('#BlockProjectChauffe-eau--wrapper').querySelectorAll('.BlockProjectChauffe-eau').forEach(function (item) {
        const response = item.querySelector('.BlockProjectChauffe-eau--response .response');
        const synthese = item.querySelector('.BlockProjectChauffe-eau--synthese .synthese');

        response.addEventListener('click', function () {
            const idItem = response.dataset.id;
            const linkItem = response.dataset.link;
            const type = response.dataset.type;

            if (type == "nouvelle_installation") {
                updateCookie('Chauffe-eau-nouvelle-installation', idItem, ["new_installation"], '/pac-chauffe-eau-get-project-by-id/', linkItem)
            } else if (type == "projet_remplacement") {
                updateCookie('Chauffe-eau-remplacement', idItem, ["replace_chauffe-eau"], '/pac-chauffe-eau-get-project-by-id/', linkItem);
            } else {
                updateCookie('Chauffe-eau-nouvelle-reference', idItem, ["reference_chauffe_eau"], '/pac-chauffe-eau-get-project-by-id/', linkItem);
            }
        })

        if (synthese) {
            synthese.addEventListener('click', function () {
                const idItem = response.dataset.id;
                const linkItem = response.dataset.link;
                const type = response.dataset.type;

                if (type == 'projet_nouvelle_reference') {
                    updateCookie('Chauffe-eau-nouvelle-reference', idItem, ["reference_chauffe_eau"], '/pac-chauffe-eau-get-project-by-id/', linkItem);
                }
            })
        }
    })
}

function updateCookie(type, id, cookiesNames, uri, linkItem) {
    const url = uri + id + '/' + type;

    fetch(url, {
        method: 'POST'
    })
        .then(response => {
            if (response.ok) {
                response.json().then((data) => {

                    for (let i = 0; i < cookiesNames.length; i++) {
                        let object = data[cookiesNames[i]];
                        object['canBeSaved'] = false;
                        object['idDim'] = id;

                        let objectString = JSON.stringify(object);
                        document.cookie = cookiesNames[i] + "=" + objectString + "; expires=" + (new Date(Date.now() + 86400 * 1000)).toUTCString();
                        window.location.replace(apiUrl + linkItem + "?id=" + id)
                    }
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function addOrDeleteClassOnClass(className, modifiedClasse, addClass = true) {
    var domElements = document.querySelectorAll(className);
    if (domElements.length > 0) {
        domElements.forEach(function (domElement) {
            if (addClass) {
                domElement.classList.add(modifiedClasse);
            } else {
                domElement.classList.remove(modifiedClasse);
            }
        });
    }
}

function updateRef(className) {
    let textRef = 'UE, ' + document.querySelector('.recommendation-ue').querySelector('.ProjectRecommended--ref-container p').textContent;
    var domElements = document.querySelectorAll(className);
    for (let i = 0; i < domElements.length; i++) {
        textRef += ' | ';
        textRef += 'UI ' + (i + 1) + ', ' + domElements[i].querySelector('.ProjectRecommended--ref-container p').textContent
    }
    document.querySelector('.ProjectResponse--references').querySelector('.ProjectResponse--content-value').textContent = textRef;
}

function changeRecommendationConsole() {
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    const recommendationConsole = document.querySelector('.recommendation-console');
    const recommendationUp = document.querySelector('.recommendation-change-up');
    const recommendationDown = document.querySelector('.recommendation-change-down');
    recommendationChangeConsole.disabled = true;
    if (recommendationConsole) {
        if (recommendationUp) recommendationUp.disabled = false;
        if (recommendationDown) recommendationDown.disabled = false;
    }

    addOrDeleteClassOnClass('.recommendation-down', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation-up', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation-console', 'd-none', false);

    updateRef('.recommendation-console');
}

function changeRecommendationDown() {
    const recommendationChangeDown = document.querySelector('.recommendation-change-down');
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    const recommendationUp = document.querySelector('.recommendation-up');
    if (recommendationUp) {
        addOrDeleteClassOnClass('.recommendation-up', 'd-none', true);
    }
    if (!document.querySelectorAll('.recommendation-change-console-no').length == 1) {
        recommendationChangeConsole.disabled = false;
    }
    addOrDeleteClassOnClass('.recommendation-console', 'd-none', true);
    recommendationChangeDown.setAttribute('data-button-selected', 'true');
    addOrDeleteClassOnClass('.recommendation', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation-down', 'd-none', false);
    updateRef('.recommendation-down');
    document.querySelector('.recommendation-change-down').disabled = true;
    document.querySelector('.recommendation-change-up').disabled = false;
}

function changeRecommendationUp() {
    const recommendationChangeUp = document.querySelector('.recommendation-change-up');
    const recommendationChangeConsole = document.querySelector('.recommendation-change-console');
    if (!document.querySelectorAll('.recommendation-change-console-no').length == 1) {
        recommendationChangeConsole.disabled = false;
    }
    const recommendationDown = document.querySelector('.recommendation-down');
    if (recommendationDown) {
        addOrDeleteClassOnClass('.recommendation-down', 'd-none', true);
    }
    addOrDeleteClassOnClass('.recommendation-console', 'd-none', true);
    recommendationChangeUp.setAttribute('data-button-selected', 'true');
    addOrDeleteClassOnClass('.recommendation', 'd-none', true);
    addOrDeleteClassOnClass('.recommendation-up', 'd-none', false);
    updateRef('.recommendation-up');
    document.querySelector('.recommendation-change-up').disabled = true;
    document.querySelector('.recommendation-change-down').disabled = false;
}

// Main.jss
// Critical JS (used to open popups
(function () {
    const elementsPopup = document.querySelectorAll('[data-popup]')
    let linksPopup = document.querySelectorAll('[data-popup-link]')
    linksPopup = Array.from(linksPopup) // Converting NodeList to Array for filter() below

    let popups = []

    elementsPopup.forEach(elementPopup => {
        if (elementPopup.dataset.popup == 'login') {
            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'login'
            })
            window.popupLogin = new PopupLogin(elementPopup, links);
            popups.push(window.popupLogin)
        } else if (elementPopup.dataset.popup == 'order') {
            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'order'
            })

            popups.push(new PopupOrder(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'search') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'search'
            })
            popups.push(new PopupSearch(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'delete') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == elementPopup.dataset.popupId
            })
            popups.push(new PopupDelete(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'save') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == elementPopup.dataset.popupId
            })
            popups.push(new PopupSave(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'gainable') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'gainable'
            })
            popups.push(new PopupGainable(elementPopup, links))
        } else if (elementPopup.dataset.popup == 'shareMail') {

            const links = linksPopup.filter(function (link) {
                return link.dataset.popupLink == 'shareMail'
            })
            popups.push(new PopupMail(elementPopup, links))
        }
    })

    const loggedInCookie = utilities.getCookie('is_logged_in')
    if (!loggedInCookie) {
        document.querySelectorAll('.CartCaracs-checkoutBtn button').forEach(checkoutButton => {
            checkoutButton.setAttribute('data-popup-link', "login")
            checkoutButton.addEventListener('click', function (event) {
                window.popupLogin.open()
            })
        })
    }

    // Initialization of login popups on some elements which require the user to be logged in.
    initAdvancedLoginPopup();

    searchSpellcheck()
}());

function searchSpellcheck() {
    const searchSpellCheck = document.querySelector('[data-suggestion]')
    if (searchSpellCheck && searchSpellCheck !== null) {
        searchSpellCheck.addEventListener('click', event => {
            const url = new URL(window.location.href)
            url.searchParams.set('search_form[search_text]', searchSpellCheck.textContent)
            window.location.href = url.href;
        })
    }
}

function openPopupOnGuideProFormSubmit(elementPopup) {
    const form = document.getElementById('formGuidePro');
    if (form.dataset.formResult === 'success') {

    } else if (form.dataset.formResult === 'fail') {
        openPopup(elementPopup)
    }
}

let refreshLink = document.getElementById('rafraichir');
if (refreshLink != null && refreshLink.value == '') {
    window.addEventListener('beforeunload', function (event) {
        if (seconds < 5) {
            event.preventDefault();
            return false;
        }
        return true;
    });
}
let dashboardAvailabilityProduct = document.querySelector('.Dashboard--availability-product');
let searchInput = document.getElementById('availability_product_form_availability_product');
let search_list = document.getElementById('search_list');
let btn_submit = document.getElementById('availability_product_form_submit');
let selectedElement = null;

if (dashboardAvailabilityProduct) {
    let openAccordion = dashboardAvailabilityProduct.querySelector('.accordion-header .accordion-button');

    searchInput.setAttribute('autocomplete', 'off');

    if (searchInput.value.length === 0) {
        document.querySelector('.message-result').style.display = 'none';
    }

    if (searchInput.value.length !== 0) {
        btn_submit.removeAttribute('disabled');
    }

    searchInput.addEventListener('keyup', function (e) {
        btn_submit.removeAttribute('disabled');

        if (searchInput.value.length === 0) {
            btn_submit.setAttribute('disabled', 'disabled');
            document.querySelector('.table-wrapper').style.display = "none";

            const elementsToHide = document.querySelectorAll('.table-search, .results-search, .message-result');

            elementsToHide.forEach(function (element) {
                element.style.display = 'none';
            });
        }

        search_list.innerHTML = '';


        if (searchInput.value.length >= 3) {

            let dataToSend = {
                searchInput: searchInput.value
            };
            fetch('/account/availability-p/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                search_list.innerHTML = '';
                let matchedData = data.matchedData;
                matchedData.forEach(element => {
                    const paragraph = document.createElement('p');
                    const firstThreeLetters = element.substring(0, 3);
                    const spanElement = document.createElement('span');
                    spanElement.classList.add("firstThreeLetters");
                    spanElement.textContent = firstThreeLetters;
                    paragraph.textContent = element.substring(3);
                    paragraph.prepend(spanElement);
                    search_list.appendChild(paragraph);
                    search_list.style.display = "block";

                    paragraph.addEventListener('click', function () {

                        if (selectedElement) {
                            selectedElement.classList.remove('selected');
                        }

                        paragraph.classList.add('selected');
                        selectedElement = paragraph;
                        searchInput.value = element;

                        btn_submit.click();
                    });
                });
            });
        } else {
            search_list.style.display = "none";
        }

    });

    openAccordion.addEventListener('click', function () {
        openAccordion.querySelector('.icon').removeAttribute('class');

        if (openAccordion.classList.contains('collapsed')) {
            openAccordion.querySelector('svg').setAttribute('class', 'icon icon-chevron-down');
            openAccordion.querySelector('svg use').setAttribute('xlink:href', '/bundles/thermorprosite/dist/icons.svg#icon-chevron-down');
        } else {
            openAccordion.querySelector('svg').setAttribute('class', 'icon icon-chevron');
            openAccordion.querySelector('svg use').setAttribute('xlink:href', '/bundles/thermorprosite/dist/icons.svg#icon-chevron');
        }
    })
}

const masterMobileHeader = document.querySelector('.FullHeaderTop-item--horizonLogo-mobile');

if (masterMobileHeader && window.getComputedStyle(masterMobileHeader).display !== "none") {
    document.querySelector('.Footer').style.marginBottom = "57px";
}


if (document.querySelectorAll('.EngagementProgramHeader--status')) {
    document.querySelectorAll('.EngagementProgramHeader--status').forEach((item) => {
        item.addEventListener('click', function () {
            const infoBullWrapper = item.closest('.FullHeaderTop-item').querySelector('.infoBull--wrapper');
            infoBullWrapper.classList.toggle("show");
        })
    });
}

let btnShowHistory = document.querySelector('.blockMonProfilAventure-button button');
if (btnShowHistory) {
    let tableBody = document.querySelector('.blockMonProfilAventure .rwd-table2 tbody');
    let loader = document.querySelector('.blockMonProfilAventure--content .loader');
    let adventureBlock = document.querySelector('.blockMonProfilAventure--content');
    const rows = tableBody.querySelectorAll('tr');
    let rowCount = 6;

    function displayRows() {
        for (let i = 0; i < rows.length; i++) {
            if (i < rowCount) {
                rows[i].style.display = '';
            } else {
                rows[i].style.display = 'none';
            }
        }
    }

    displayRows();
    adventureBlock.addEventListener('scroll', function () {
        let scrollTop = adventureBlock.scrollTop;
        loader.style.top = scrollTop + 'px';
    });


    btnShowHistory.addEventListener('click', function () {
        loader.style.display = "block";
        setTimeout(() => {
            loader.style.display = "none";
            rowCount += 5;
            displayRows();
        }, 1000);

    })
}

let btnShowOrderHistory = document.querySelector('.blockMonProfilEquipement-button button');
if (btnShowOrderHistory) {
    let tableOrderBody = document.querySelector('.blockMonProfilEquipement .rwd-table tbody');
    let loaderOrder = document.querySelector('.blockMonProfilEquipement .loader');
    let equipmentBlock = document.querySelector('.blockMonProfilEquipement--content');
    const rows = tableOrderBody.querySelectorAll('tr');
    let rowCount = 10;

    function displayRowsOrders() {
        for (let i = 0; i < rows.length; i++) {
            if (i < rowCount) {
                rows[i].style.display = '';
            } else {
                rows[i].style.display = 'none';
            }
        }
    }

    displayRowsOrders();
    equipmentBlock.addEventListener('scroll', function () {
        let scrollTop = equipmentBlock.scrollTop;
        loaderOrder.style.top = scrollTop + 'px';
    });


    btnShowOrderHistory.addEventListener('click', function () {
        loaderOrder.style.display = "block";
        setTimeout(() => {
            loaderOrder.style.display = "none";
            rowCount += 10;
            displayRowsOrders();
        }, 1000);

    })
}

(function () {
    let $popupButtons = $('button[data-popup-link="search"], #hero_search_form_search_text, #mobile_search_form_search_text');

// Add click event listener to the button
    $popupButtons.on('click', function () {

        $.ajax({
            url: '/search-popup',
            type: 'GET',
            beforeSend: function (data) {
                let loaderHtml = '<div class="loadingImg"><img src="/bundles/thermorprosite/images/animation/thermor-animation-chargement.svg" alt="chargement"/></div>';

                $('.PopupSearch').find('.PopupSearch--wrapper').html(loaderHtml);
            },
            success: function (data) {
                $('.PopupSearch').find('.PopupSearch--wrapper').html(data);
                fetch('/data-modal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        const paginations = document.querySelectorAll('.pagination-popin .paginate-pop');
                        const dataLength = data?.length;
                        let currentPage = 1;

                        function createDotPagination() {
                            paginations.forEach(pagination => {
                                pagination.innerHTML = '';
                                for (let i = 1; i <= dataLength; i++) {
                                    const dot = document.createElement('span');
                                    dot.classList.add('dot');
                                    if (i === currentPage) dot.classList.add('active'); // Highlight current page
                                    dot.addEventListener('click', () => {
                                        currentPage = i;
                                        updatePageContent(currentPage);
                                        updateDotPagination();
                                    });
                                    pagination.appendChild(dot);
                                }
                            });

                        }

                        function updateDotPagination() {
                            paginations.forEach(pagination => {
                                // pagination.innerHTML = '';
                                const dots = pagination.querySelectorAll('.dot');
                                dots.forEach((dot, index) => {
                                    dot.classList.toggle('active', index === currentPage - 1);
                                });
                            });
                        }

                        function updatePageContent(currentPage) {
                            const pageIndex = currentPage - 1;
                            const pageData = data[pageIndex];

                            const paginationPopinItems = document.querySelectorAll('.pagination-popin');
                            paginationPopinItems.forEach(paginationPopinItem => {
                                const popupParent = paginationPopinItem.closest('.PopupSearch-promote');
                                const title = popupParent.querySelector('.PopupSearch-promote--title');
                                const desc = popupParent.querySelector('.PopupSearch-promote--desc');
                                const link = popupParent.querySelector('.PopupSearch-promote--link');
                                const img = popupParent.querySelector('.PopupSearch-promote--img img');

                                if (pageData) {
                                    title ? title.textContent = pageData.title || '' : '';
                                    desc ? desc.innerHTML = pageData.description || '' : '';
                                    link ? link.textContent = pageData.call_to_action_text || '' : '';
                                    link?.setAttribute('href', pageData.target_url || '');
                                    img?.setAttribute('src', pageData.image || '');
                                }
                            });
                        }

                        // Initialize pagination and content
                        createDotPagination();
                        updatePageContent(currentPage);
                    })

                    .catch(error => {
                        console.error(error);
                    });

                let popinSearch = document.querySelector('.PopupSearch');

                let loader = document.querySelector('.PopupSearch-resultats .loader');
                if (loader) {
                    loader.style.display = "none";
                }
                const inputSearch = document.querySelector('#hero_search_form_search_text');

                if (inputSearch && popinSearch) {
                    const popup = new Popup(popinSearch, [inputSearch], "focus");
                }
                if (popinSearch) {
                    let searchAlgolia = document.getElementById('header_search_form_search_text');
                    let ulSuggestionOutils = document.querySelector('#suggestion-outils');
                    let ulOutils = document.querySelector('#search-outils');
                    let ulOutilsRes = document.querySelector('#outils-search-res');
                    if (ulOutils) {
                        ulOutils.style.display = 'none';
                    }
                    let fetchTimeOutSuggestion = 0;
                    searchAlgolia.addEventListener('input', function (e) {
                        clearTimeout(fetchTimeOutSuggestion);
                        let ValueInput = this.value;
                        let ValueInputToSend = {
                            ValueInput: ValueInput
                        };

                        fetchTimeOutSuggestion = setTimeout(() => {
                            fetch('/suggestion', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(ValueInputToSend)
                            })
                                .then(response => response.json())
                                .then(data => {
                                    /**********************************************************/

                                    let suggestionPopin = document.querySelectorAll('#suggestion-popin li');
                                    let UlsuggestionPopin = document.querySelector('#suggestion-popin');
                                    let UlsuggestionSer = document.querySelector('#suggestion-search-res');

                                    UlsuggestionPopin.innerHTML = "";
                                    UlsuggestionSer.innerHTML = "";
                                    data.termSuggestion.forEach((term, index) => {
                                        if (UlsuggestionPopin) {
                                            let newListItem = document.createElement('li');
                                            newListItem.classList.add('search-record');
                                            newListItem.innerHTML = '<a href="' + data.routeResult + '?search=' + term + '">' + term + '</a>';
                                            newListItem.style.visibility = 'visible';
                                            const elementUl = document.getElementById("suggestion-popin");
                                            if (elementUl) {
                                                const numberOfLiElements = elementUl.getElementsByTagName("li").length;
                                                if (numberOfLiElements === data.termSuggestion.length) {
                                                    if (numberOfLiElements > 0) {
                                                        const lastLiElement = elementUl.getElementsByTagName("li")[numberOfLiElements - 1];
                                                        lastLiElement.style.borderBottom = 'none';
                                                    }
                                                }
                                            }
                                            UlsuggestionPopin.appendChild(newListItem);
                                            if(index<2) {
                                                $(UlsuggestionSer).append(
                                                    '<li><a class="hit-result" href="' + data.routeResult + '?search=' + term + '">' +
                                                    '<span class="text">' + term + '</span>' +
                                                    '<span class="tag">Suggestions</span>' +
                                                    '</a></li>'
                                                );
                                            }

                                            suggestionPopin.forEach(element => {
                                                element.style.visibility = 'hidden';
                                            });
                                        }
                                    });

                                    /****************************************************************/
                                    const suggestionSearch = data.suggestionSearch ? data.suggestionSearch.slice(0, 3) : [];
                                    ulOutils.innerHTML = "";
                                    ulOutilsRes.innerHTML = "";
                                    if (suggestionSearch.length === 0) {
                                        if (ulSuggestionOutils) ulSuggestionOutils.style.display = 'block';
                                        if (ulOutils) ulOutils.style.display = 'none';
                                    } else {
                                        let outilIndex = 0;
                                        suggestionSearch.forEach((item, index) => {

                                            let newListItem = document.createElement('li');
                                            newListItem.classList.add('search-record');
                                            newListItem.innerHTML = '<a href="' + data.routeResult + '?search=' + item.title + '">' + item.title + '</a>';
                                            newListItem.style.visibility = 'visible';
                                            ulOutils.appendChild(newListItem);
                                            if(outilIndex < 1) {
                                                if(!containsOutil(item.title)) {
                                                    $(ulOutilsRes).append(
                                                        '<li><a class="hit-result" href="' + data.routeResult + '?search=' + item.title + '">' +
                                                        '<span class="text">' + item.title + '</span>' +
                                                        '<span class="tag">Outils</span>' +
                                                        '</a></li>'
                                                    );
                                                    outilIndex = outilIndex + 1;
                                                }
                                            }
                                        });
                                        if (ulOutils) ulOutils.style.display = 'block';
                                        if (ulSuggestionOutils) ulSuggestionOutils.style.display = 'none';
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        }, 1000);

                    });
                    function containsOutil(text) {
                        return $('.PopupSearch-resultats--titre li').filter(function() {
                            return $(this).text().includes(text.trim());
                        }).length > 0;
                    }

                    let PopupResult = document.querySelectorAll('.PopupSearch-resultats');
                    let fetchTimeOut = 0;
                    searchAlgolia.addEventListener('input', function () {
                        clearTimeout(fetchTimeOut);
                        let descResult = document.querySelector('.PopupSearch-resultats-desc');
                        let query = this.value;
                        let dataToSend = {
                            titleSearchable: query
                        };

                        loader.style.display = "block";
                        if (loader) {
                            loader.style.borderTop = '6px solid #db3434';
                        }
                        if(query === '') {
                            $('.PopupSearch-resultats').hide();
                            $('.PopupSearch-results').show();
                            $('#header_search_form_search_text').css('border-bottom', '2px solid #dadbdc');
                            $('#header_search_form_search_text').css('border-bottom-right-radius', '8px');
                            $('#header_search_form_search_text').css('border-bottom-left-radius', '8px');
                            $('#header_search_form_search_text').css('background-color', 'white');

                        }
                        else {
                            $('.PopupSearch-resultats').show();
                            $('.PopupSearch-results').hide();
                            $('#header_search_form_search_text').css('background-color', '#FAFAFA');
                            $('#header_search_form_search_text').css('border-bottom-right-radius', '0');
                            $('#header_search_form_search_text').css('border-bottom-left-radius', '0');
                        }
                        fetchTimeOut = setTimeout(() => {
                            fetch('/content-algolia-search', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(dataToSend)
                            })
                                .then(response => response.json())
                                .then(data => {
                                    PopupResult.forEach(function (itemPopupResult) {
                                        let result = itemPopupResult.querySelector(
                                            '.PopupSearch-resultats--titre');
                                        let seeMoreResult = itemPopupResult.querySelector('.see-more-result');
                                        let ulElement = result.querySelector('ul');
                                        result.style.display = 'none';
                                        seeMoreResult.style.display = 'none';

                                        if (query.length === 0) {
                                            descResult.style.display = 'block';
                                            result.style.display = 'none';
                                            seeMoreResult.style.display = 'none';
                                        } else {
                                            descResult.style.display = 'none';
                                            result.style.display = 'block';
                                            seeMoreResult.style.display = 'block';
                                            result.dataset.insightsIndex = data.indexName;

                                            let counter = 0;
                                            let html = '';
                                            data.result.forEach(function (item) {
                                                if (counter < data.result.length) {
                                                    html += `<li><a href="${item.url}" class="hit-result" data-insights-search-keyword="${query}" data-insights-object-id="${item.objectID}" data-insights-position="${item.position}" data-insights-query-id="${data.queryID}"><span class="text">${item.title}</span><span class="tag">${item.contentType}</span></a></li>`;
                                                    counter++;
                                                }
                                            });
                                            ulElement.innerHTML = html;
                                        }
                                    });
                                    dataLayer.push({
                                        algoliaUserToken: data.userToken
                                    });
                                    dataLayer.push({
                                        event: 'Hits Viewed',
                                        'searched-keyword': query,
                                    });
                                    loader.style.display = "none";
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        }, 1000);
                    });


                }

                let hiddenValueInput = document.getElementById('searchResults-items');

                if (hiddenValueInput) {
                    let searchPageResult = hiddenValueInput.value;
                    let currentPath = window.location.pathname;
                    let dataId = null;

                    if (currentPath === searchPageResult) {
                        let searchT = document.querySelector('#search_form_search_text');
                        let searchText = searchT.value ?? null;
                        fetch('/data-modal', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        })
                            .then(response => response.json())
                            .then(data => {
                                const paginationHighlighted = document.querySelector('.pagination-highlighted');
                                const dataLength = data.length;

                                if (dataLength <= 1) {
                                    if (paginationHighlighted) paginationHighlighted.style.display = 'none';
                                } else {
                                    paginationHighlighted.style.display = 'block';
                                }

                                let pageRecentHighlighted = document.querySelector('.pagination-highlighted .currentPage-highlighted');
                                let nextBtnHighlighted = document.querySelector('.pagination-highlighted .next-highlighted');
                                let previousBtnHighlighted = document.querySelector('.pagination-highlighted .previous-highlighted');
                                let titleHighlighted = document.querySelector('.searchResults-article-title');
                                let descHighlighted = document.querySelector('.searchResults-article-content p');
                                let linkHighlighted = document.querySelector('.searchResults-article-cta a');
                                let imgHighlighted = document.querySelector('.searchResults-article-img img');

                                nextBtnHighlighted.addEventListener('click', function (event) {
                                    event.preventDefault();
                                    if (parseInt(document.querySelector('.nbPages-highlighted').textContent) > parseInt(pageRecentHighlighted.textContent)) {
                                        if (parseInt(pageRecentHighlighted.textContent) + 1 === parseInt(document.querySelector('.nbPages-highlighted').textContent)) {
                                            event.target.classList.add('disabled');
                                        }
                                        let btnprev = event.target.closest('.paginate-highlighted').querySelector('.previous-highlighted');

                                        btnprev.classList.remove('disabled');
                                        pageRecentHighlighted.textContent = parseInt(pageRecentHighlighted.textContent) + 1;
                                        const pageData = data[parseInt(pageRecentHighlighted.textContent) - 1];
                                        if (pageData) {
                                            if (titleHighlighted) titleHighlighted.textContent = pageData.title || '';
                                            if (descHighlighted) descHighlighted.textContent = pageData.description || '';
                                            if (linkHighlighted) linkHighlighted.textContent = pageData.call_to_action_text || '';
                                            if (linkHighlighted) linkHighlighted.setAttribute('href', pageData.target_url || '');
                                            imgHighlighted.setAttribute('src', pageData.image || '');
                                        }
                                    }
                                });

                                previousBtnHighlighted.addEventListener('click', function (event) {
                                    event.preventDefault();
                                    if (parseInt(pageRecentHighlighted.textContent) > 1) {

                                        if (parseInt(pageRecentHighlighted.textContent) - 1 === 1) {
                                            event.target.classList.add('disabled');
                                        }
                                        let btnnext = event.target.closest('.paginate-highlighted').querySelector('.next-highlighted');

                                        btnnext.classList.remove('disabled');
                                        pageRecentHighlighted.textContent = parseInt(pageRecentHighlighted.textContent) - 1;
                                        const pageData = data[parseInt(pageRecentHighlighted.textContent) - 1];
                                        if (pageData) {
                                            titleHighlighted.textContent = pageData.title || '';
                                            if (descHighlighted) descHighlighted.textContent = pageData.description || '';
                                            if (linkHighlighted) linkHighlighted.textContent = pageData.call_to_action_text || '';
                                            if (linkHighlighted) linkHighlighted.setAttribute('href', pageData.target_url || '');
                                            imgHighlighted.setAttribute('src', pageData.image || '');
                                        }
                                    }
                                });
                            })
                            .catch(error => {
                                console.error(error);
                            });


                        let nextList = document.querySelectorAll('.next-list');
                        let previousList = document.querySelectorAll('.previous-list');

                        nextList.forEach(function (element) {
                            element.addEventListener('click', function (event) {
                                event.preventDefault();
                                let dataId = element.closest('.pagination-list').getAttribute('data-id');
                                let pageFollowing = element.closest('.pagination-list').querySelector('.currentPage-list');
                                let previous = element.closest('.pagination-list').querySelector('.previous-list');
                                let nbPagesList = element.closest('.pagination-list').querySelector('.nbPages-list');
                                previous.style.pointerEvents = 'auto';
                                if (parseInt(pageFollowing.textContent.trim()) + 1 > 1) {
                                    previous.classList.add('active')
                                }

                                if (parseInt(pageFollowing.textContent.trim()) + 1 === parseInt(nbPagesList.textContent.trim())) {
                                    element.classList.remove('active')
                                } else {
                                    element.classList.add('active')
                                }

                                if (parseInt(pageFollowing.textContent.trim()) >= 1 && parseInt(nbPagesList.textContent.trim()) > parseInt(pageFollowing.textContent.trim())) {
                                    fetchData(dataId, pageFollowing, element);
                                }

                            });
                        });

                        previousList.forEach(function (element) {
                            element.addEventListener('click', function (event) {
                                //element.classList.remove('active')
                                event.preventDefault();
                                let dataId = element.closest('.pagination-list').getAttribute('data-id');
                                let pageFollowing = element.closest('.pagination-list').querySelector('.currentPage-list');
                                let nbPagesList = element.closest('.pagination-list').querySelector('.next-list');
                                if (parseInt(pageFollowing.textContent.trim()) - 1 > 1) {
                                    nbPagesList.classList.add('active')
                                    fetchData(dataId, pageFollowing, element);
                                } else if (parseInt(pageFollowing.textContent.trim()) - 1 === 1) {
                                    element.style.pointerEvents = 'none';
                                    element.classList.remove('active')
                                    fetchData(dataId, pageFollowing, element);
                                }
                            });
                        });

                    }
                }
            }
        });
    });
    if (location.hash === '#search') {
        $popupButtons.first().trigger('click');
    }

    if (document.querySelector('.pagination-highlighted')) {
        fetch('/data-modal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                const paginationHighlighted = document.querySelector('.pagination-highlighted');
                const dataLength = data.length;

                if (dataLength <= 1) {
                    if (paginationHighlighted) paginationHighlighted.style.display = 'none';
                } else {
                    paginationHighlighted.style.display = 'block';
                }

                let pageRecentHighlighted = document.querySelector('.pagination-highlighted .currentPage-highlighted');
                let nextBtnHighlighted = document.querySelector('.pagination-highlighted .next-highlighted');
                let previousBtnHighlighted = document.querySelector('.pagination-highlighted .previous-highlighted');
                let titleHighlighted = document.querySelector('.searchResults-article-title');
                let descHighlighted = document.querySelector('.searchResults-article-content p');
                let linkHighlighted = document.querySelector('.searchResults-article-cta a');
                let imgHighlighted = document.querySelector('.searchResults-article-img img');

                nextBtnHighlighted.addEventListener('click', function (event) {
                    event.preventDefault();
                    if (parseInt(document.querySelector('.nbPages-highlighted').textContent) > parseInt(pageRecentHighlighted.textContent)) {
                        if (parseInt(pageRecentHighlighted.textContent) + 1 === parseInt(document.querySelector('.nbPages-highlighted').textContent)) {
                            event.target.classList.add('disabled');
                        }
                        let btnprev = event.target.closest('.paginate-highlighted').querySelector('.previous-highlighted');

                        btnprev.classList.remove('disabled');
                        pageRecentHighlighted.textContent = parseInt(pageRecentHighlighted.textContent) + 1;
                        const pageData = data[parseInt(pageRecentHighlighted.textContent) - 1];
                        if (pageData) {
                            if (titleHighlighted) titleHighlighted.textContent = pageData.title || '';
                            if (descHighlighted) descHighlighted.textContent = pageData.description || '';
                            if (linkHighlighted) linkHighlighted.textContent = pageData.call_to_action_text || '';
                            if (linkHighlighted) linkHighlighted.setAttribute('href', pageData.target_url || '');
                            imgHighlighted.setAttribute('src', pageData.image || '');
                        }
                    }
                });

                previousBtnHighlighted.addEventListener('click', function (event) {
                    event.preventDefault();
                    if (parseInt(pageRecentHighlighted.textContent) > 1) {

                        if (parseInt(pageRecentHighlighted.textContent) - 1 === 1) {
                            event.target.classList.add('disabled');
                        }
                        let btnnext = event.target.closest('.paginate-highlighted').querySelector('.next-highlighted');

                        btnnext.classList.remove('disabled');
                        pageRecentHighlighted.textContent = parseInt(pageRecentHighlighted.textContent) - 1;
                        const pageData = data[parseInt(pageRecentHighlighted.textContent) - 1];
                        if (pageData) {
                            titleHighlighted.textContent = pageData.title || '';
                            if (descHighlighted) descHighlighted.textContent = pageData.description || '';
                            if (linkHighlighted) linkHighlighted.textContent = pageData.call_to_action_text || '';
                            if (linkHighlighted) linkHighlighted.setAttribute('href', pageData.target_url || '');
                            imgHighlighted.setAttribute('src', pageData.image || '');
                        }
                    }
                });
            })
            .catch(error => {
                console.error(error);
            });
    }
})();


let nextList = document.querySelectorAll('.next-list');
let previousList = document.querySelectorAll('.previous-list');

const searchPageSize = 10;

nextList.forEach(function (element) {
    element.addEventListener('click', function (event) {
        event.preventDefault();
        let currentPage = parseInt(element.closest('.pagination-list').querySelector('.currentPage-list').textContent.trim());
        let nbPagesList = parseInt(element.closest('.pagination-list').querySelector('.nbPages-list').textContent.trim());
        if (currentPage < nbPagesList) {
            changePage(currentPage + 1, element);
        }
    });
});

previousList.forEach(function (element) {
    element.addEventListener('click', function (event) {
        event.preventDefault();
        let currentPage = parseInt(element.closest('.pagination-list').querySelector('.currentPage-list').textContent.trim());
        if (currentPage > 1) {
            changePage(currentPage - 1, element);
        }
    });
});

function changePage(currentPage, element) {
    let nbPages = parseInt(element.closest('.pagination-list').querySelector('.nbPages-list').textContent.trim());
    let previousBtn = element.closest('.pagination-list').querySelector('.previous-list');
    let nextBtn = element.closest('.pagination-list').querySelector('.next-list');
    let currentPageElement = element.closest('.pagination-list').querySelector('.currentPage-list');
    currentPageElement.textContent = currentPage;

    let viewed = false;
    let listItems = element.closest('.searchResults-typelist-list').querySelectorAll('li');
    listItems.forEach((item, index) => {
        let aElement = item.querySelector('a');
        if (index >= (currentPage - 1) * searchPageSize && index < currentPage * searchPageSize) {
            item.classList.remove('hidden');
            if (aElement.dataset.insightsViewedObjectId) {
                viewed = true;
            } else {
                aElement.setAttribute('data-insights-object-id', aElement.dataset.hiddenInsightsObjectId);
                aElement.setAttribute('data-insights-query-id', aElement.dataset.hiddenInsightsQueryId);
                aElement.setAttribute('data-insights-position', aElement.dataset.hiddenInsightsPosition);
            }
        } else {
            item.classList.add('hidden');
        }
    });

    if (!viewed) {
        const searchText = document.getElementById('search_form_search_text').value;
        dataLayer.push({
            event: 'Hits Viewed',
            'searched-keyword': searchText,
        });
        setTimeout(() => {
            Array.from(document.querySelectorAll('[data-insights-object-id]')).forEach((element) => {
                element.setAttribute('data-insights-viewed-object-id', element.dataset.insightsObjectId);
                element.setAttribute('data-insights-viewed-query-id', element.dataset.insightsQueryId);
                element.setAttribute('data-insights-viewed-position', element.dataset.insightsPosition);
                delete element.dataset.insightsObjectId;
                delete element.dataset.insightsQueryId;
                delete element.dataset.insightsPosition;
            });
        }, "3000");
    }

    if (currentPage <= 1) {
        previousBtn.classList.remove('active');
        previousBtn.style.pointerEvents = 'none';
    } else {
        previousBtn.classList.add('active');
        previousBtn.style.pointerEvents = 'auto';
    }
    if (currentPage < nbPages) {
        nextBtn.classList.add('active');
        nextBtn.style.pointerEvents = 'auto';
    } else {
        nextBtn.classList.remove('active');
        nextBtn.style.pointerEvents = 'none';
    }
}

let dragonModal = document.querySelector('.notifeQueteStatut.dragon');
let earnModal = document.querySelector('.notifeQueteStatut.earn');
const pawDragons = document.querySelectorAll('.drag-click');
let popinGold = document.querySelector('.dragon-gold');
let popinDragon = document.querySelector('.dragon-enable');
if (popinDragon) {
    popinDragon.style.display = "none";
}
if (popinGold) {
    popinGold.style.display = "none";
}
const loadingDragon = document.getElementById('loadingDragon');

pawDragons.forEach(drag => {
    drag.addEventListener('click', function (e) {
        e.preventDefault();
        var typeDragonInput = document.getElementById('typeDragon');
        let typeDragonValue = '';

        if (typeDragonInput) {
            typeDragonValue = typeDragonInput.value.trim();
        }
        hidePaws();
        if (loadingDragon) {
            loadingDragon.style.display = "flex";
        }
        fetch('/info-paw', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({typeDragon: typeDragonValue})
        })
            .then(response => response.json())
            .then(data => {
                loadingDragon.style.display = "none";
                let pawDailyPlay = data?.idOfferTechnical?.pawDailyPlay || false;
                let availableNormalDragon = data?.idOfferTechnical?.DRAGON_PAW || false;
                let availableGoldDragon = data?.idOfferTechnical?.DRAGON_PAW_GOLD || false;
                let status = data?.statusMv || '';
                let pawDailyWin = data?.notifGain?.C_DRAGON_PAW_DAILY_WIN || false;
                let pawGoldDailyWin = data?.notifGain?.C_DRAGON_PAW_GOLD_DAILY_WIN || false;

                if (pawDailyWin) {
                    if (earnModal.getAttribute('aria-hidden') === 'false') {
                        earnModal.setAttribute('aria-hidden', 'true');
                    }

                }
                if (pawGoldDailyWin) {
                    if (earnModal.getAttribute('aria-hidden') === 'false') {
                        earnModal.querySelector('.notifeQueteStatut-title').textContent = "Vous êtes parmi les premiers à avoir retrouvé la trace du dragon d'Or !"
                        earnModal.querySelector('img').setAttribute('src', '/bundles/thermorprosite/images/taverne/dragon/pas_x2/empreintes_x2_or.png')
                        earnModal.setAttribute('aria-hidden', 'true');
                    }
                }

                if (pawDailyWin || pawGoldDailyWin) {
                    let rewards = data.rewards;
                    let counters = data.counters.counter;
                    let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                    let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                    let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                    let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
                    let xpReward = maxxing.extractRewards(totalXpRewards, cxpRewards);
                    let lingotRewards = maxxing.extractRewards(totalLingotsRewards, clingotRewards);

                    if (xpReward.reward > 0 || lingotRewards.reward > 0) {
                        maxxing.apiMaxxing(data, xpReward, lingotRewards);
                    }
                    return;
                }

                if (pawDailyPlay && typeDragonValue === "normal") {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinDragon.style.display = "block";
                } else if (pawDailyPlay && typeDragonValue === 'gold') {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinGold.style.display = "block";
                } else if (availableNormalDragon && typeDragonValue === "normal") {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinDragon.style.display = "block";
                } else if (availableGoldDragon && typeDragonValue === 'gold') {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinGold.style.display = "block";
                } else if (status === 3 && typeDragonValue === "normal") {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinDragon.style.display = "block";
                } else if (status === 3 && typeDragonValue === 'gold') {
                    dragonModal.setAttribute('aria-hidden', 'true');
                    popinGold.style.display = "block";
                }
            })
            .catch(error => {
                console.error(error);
                loadingDragon.style.display = "none";
            });
    });
});

function hidePaws() {
    let paws = document.querySelector(".pathDragon");
    let goldPaws = document.querySelector(".goldPathDragon");
    if (paws) {
        paws.style.display = 'none';
    }
    if (goldPaws) {
        goldPaws.style.display = 'none';
    }
}


let submitVisibility = document.getElementById("visibility_page_creation_submit");
const eventFunc = function (e) {
    e.preventDefault();
    let btn = $(this);
    let form = btn.closest('form');
    let formData = form.serializeArray();
    $("#visibility_page_creation_submit").hide();
    $.ajax({
        url: '/account/visibility/page/create',
        type: 'POST',
        data: formData,
        beforeSend: function () {
            btn.prop('disabled', true);
            $('#loadingDr').removeClass('d-none');
        },
        success: function (data) {
            form.parent().html($(data).find('#creationForm'));
            submitVisibility = document.getElementById("visibility_page_creation_submit");
            if (submitVisibility) {
                submitVisibility.addEventListener("click", eventFunc);
            }
            $('.creation-form__form-section--action p').addClass('d-none');
            $('#loadingDr').removeClass('d-none');
            $("#visibility_page_creation_submit").hide();
            setTimeout(function () {
                window.location.href = '/account/visibility/page/creation-confirmation';
            }, 100);
        },
        error: function (data) {
            form.parent().html($(data).find('#creationForm'));
            $('.creation-form__main').html($(data.responseText).find('#creationForm'));
            submitVisibility = document.getElementById("visibility_page_creation_submit");
            if (submitVisibility) {
                submitVisibility.addEventListener("click", eventFunc);
            }
        }
    });
}
if (submitVisibility) {
    submitVisibility.addEventListener("click", eventFunc);
}

///// maxxing - part 2
export function sendPostRequest(dataToSend) {
    let cxpMaxxingDom = document.querySelector('.cxp-maxxing');
    if (cxpMaxxingDom !== null) {
        const url = '/info-doc';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization-Maxxing': `Token ${maxxing.apiToken}`
            },
            body: JSON.stringify(dataToSend)
        })
            .then(response => response.json())
            .then(data => {
                if (data.webTransaction_request_response_code.ticket_msg.value != "") {
                    let targets = data.webTransaction_request_response_code.ticket_msg.value.split(/\r?\n/);
                    maxxing.apiTarget(targets)
                }
                let rewards = data.webTransaction_request_response_code.rewards;
                if (rewards) {
                    let counters = data.webTransaction_request_response_code.counters.counter;
                    let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                    let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                    let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                    let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
                    let xpReward = maxxing.extractRewards(totalXpRewards, cxpRewards);
                    let lingotRewards = maxxing.extractRewards(totalLingotsRewards, clingotRewards);

                    if (xpReward.reward > 0 || lingotRewards.reward > 0) {
                        maxxing.apiMaxxing(data,xpReward,lingotRewards);
                    }
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
}

///// end maxxing - part 2
function isWebView() {
    var userAgent = window.navigator.userAgent.toLowerCase(),
        isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent),
        isIOS = /iphone|ipod|ipad/.test(userAgent),
        isIOSWebView = isIOS && !isSafari && !/crios/.test(userAgent) && !/fxios/.test(userAgent),
        isAndroidWebView = /android/.test(userAgent) && /wv/.test(userAgent);

    return isIOSWebView || isAndroidWebView;
}

document.addEventListener('DOMContentLoaded', function () {
    if (isWebView()) {
        document.cookie = "isWebView=true; path=/";
    } else {
        document.cookie = "isWebView=false; path=/";
    }

})


const roomItems = document.querySelectorAll('.simulhome-list-piece .simulhome-item-piece');
const simulhomeAddZone = document.querySelector('.simulhome-add-zone');
const simulhomeGroupAccordion = document.querySelector('.simulhome-group-accordion');
const elementsPieceInput = document.getElementById('elements-piece-input');
let counterAccordion = 0;
const buttonPieces = document.getElementById('form_pieces_submit');


function handleInput(inputElement) {
    inputElement.addEventListener("input", function (event) {
        this.value = this.value.replace(/[^0-9.,]/g, "");
        const parts = this.value.split(/[,\.]/);

        if (parts.length > 2) {
            this.value = parts[0] + ',' + parts.slice(1).join('');
        } else {
            // If there is only one separator, ensure it's correctly placed
            this.value = this.value.replace(/(,\d*)(?=.*\.)/g, ''); // Remove the comma if there’s a decimal after it
        }
    });
}

function countLiElementsInAccordion() {
    const count = document.querySelectorAll('.form_pieces').length;
    if (count === 0) {
        if (simulhomeAddZone) {
            simulhomeAddZone.classList.remove('d-none');
            simulhomeAddZone.classList.add('d-flex');
            if (buttonPieces) {
                buttonPieces.setAttribute('disabled', 'disabled');
                buttonPieces.classList.remove('btn');
            }
        }
    } else {
        if (buttonPieces) {
            buttonPieces.removeAttribute('disabled');
            buttonPieces.classList.remove('btn');
        }
    }

    if (elementsPieceInput) {
        return elementsPieceInput.value = count;
    }

}

function initializeSliders() {
    const sliders = document.querySelectorAll(".temperature-slider");

    sliders.forEach((temperatureSlider) => {
        const parent = temperatureSlider.closest(".range-item");
        if (!parent) {
            console.warn("Parent .range-item not found for slider", temperatureSlider);
            return;
        }
        const temperatureStepper = parent.querySelector(".simulhome-inputText.temperature-stepper");
        const activeLine = parent.querySelector(".active-line");
        const activeDot = parent.querySelector(".active-dot");
        
        if (!temperatureStepper || !activeLine || !activeDot) {
            console.warn("Missing elements in .range-item", parent);
            return;
        }
        function updateSliderFromStepper() {
            let value = parseInt(temperatureStepper.value);
            if (value < 17) value = 17;
            if (value > 26) value = 26;

            temperatureSlider.value = value;
            updateSliderVisual(value);
        }

        function updateStepperFromSlider() {
            temperatureStepper.value = temperatureSlider.value;
            updateSliderVisual(temperatureSlider.value);
        }

        function updateSliderVisual(value) {
            const percentage = ((value - 17) / (26 - 17)) * 100;
            activeLine.style.width = `${percentage}%`;
            activeDot.style.left = `${percentage}%`;
        }

        temperatureStepper.addEventListener('keypress', function (e) {
            const char = String.fromCharCode(e.which);
            if (!/[\d,.]/.test(char)) {
                e.preventDefault(); // Prevent any character that isn't a number, dot, or comma
            }
        });

        temperatureStepper.addEventListener('input', updateSliderFromStepper);
        temperatureSlider.addEventListener('input', updateStepperFromSlider);

        updateSliderFromStepper();
    });
}


initializeSliders();
countLiElementsInAccordion();

function setupEventListenersForAccordionItem(accordionItem) {
    let surfaceInput = accordionItem.querySelector('.wicon-calcul');
    let ceilingHeight = accordionItem.querySelector('.ceiling-height');

    if (surfaceInput) handleInput(surfaceInput);
    if (ceilingHeight) handleInput(ceilingHeight);
    initializeSliders();

    // Setup click event for delete icons
    var images = accordionItem.querySelectorAll(".delete-icon");
    images.forEach(function (image) {
        image.addEventListener("click", function () {
            const accordionItem = image.closest('li');
            if (accordionItem) {
                const nextHr = accordionItem.nextElementSibling;
                accordionItem.remove();
                if (elementsPieceInput) countLiElementsInAccordion();
                if (nextHr && nextHr.tagName === 'HR') {
                    nextHr.remove();
                }
            }
        });
    });

    const popupElement = accordionItem.querySelector('[data-popup="calculModalCenter"]');
    const popupLinks = accordionItem.querySelectorAll('[data-popup-link="calculModalCenter"]');
    const Popupcalc = new Popup(popupElement, popupLinks);

    const longueurInput = accordionItem.querySelector('.Longueur');
    const largeurInput = accordionItem.querySelector('.Largeur');
    const resultatInput = accordionItem.querySelector('.Resultat');
    const btnSubmit = accordionItem.querySelector('.btn-submit');
    const btnClose = accordionItem.querySelector('.simulhome-popup-close');
    const formPiecesSurface = accordionItem.querySelector('#form_pieces_surface');

    function validateInput(input) {
        const value = input.value.trim();
        const normalizedValue = value.replace(',', '.'); // Replace commas with dots
        const isValid = !isNaN(parseFloat(normalizedValue)) && isFinite(normalizedValue);

        if (isValid) {
            input.classList.remove('error-postal');
        } else {
            input.classList.add('error-postal');
        }

        return isValid ? parseFloat(normalizedValue) : NaN;
    }

    function calculerSurface() {
        const longueur = validateInput(longueurInput);
        const largeur = validateInput(largeurInput);

        if (!isNaN(longueur) && !isNaN(largeur)) {
            const surface = longueur * largeur;
            if (surface % 1 === 0) {
                resultatInput.value = surface; // Display whole number (integer)
            } else {
                resultatInput.value = surface.toFixed(2); // Display float with two decimals
            }
            return surface;
        } else {
            resultatInput.value = "0";
            return 0;
        }
    }

    longueurInput.addEventListener('input', () => calculerSurface());
    largeurInput.addEventListener('input', () => calculerSurface());

    btnSubmit.addEventListener('click', function (event) {
        event.preventDefault();
        const surface = calculerSurface();
        if (surface) {
            formPiecesSurface.value = surface;
            accordionItem.querySelector('.simulhome-popup').setAttribute('data-popup-state', 'q');
            Popupcalc.close();
        }
    });

    // Handle popup close button click
    btnClose.addEventListener('click', function () {
        document.querySelector('.simulhome-popup').setAttribute('data-popup-state', 'closed');
    });
}

let accordionCounter = 0;

let cookiePiece = getCookie('simulhome_radiator');
if (cookiePiece){
    dataCookiePieces(cookiePiece);
}
function dataCookiePieces(cookiePiece) {
    try {
        fetch('/get_cookie_data')
            .then(response => response.json())
            .then(parsedData => {
                const pieces = parsedData.pieces; // No index access here
                if (Array.isArray(pieces)) {
                    pieces.forEach(function (piece) {
                        processPiece(piece);
                    });
                } else if (typeof pieces === 'object' && pieces !== null) {
                    processPiece(pieces); // Directly process object
                } else {
                    console.warn('No valid pieces found:', pieces);
                }
            })
            .catch(error => {
                console.error('Error fetching or parsing cookie data:', error);
            });
    } catch (error) {
        console.error('Failed to parse cookiePiece:', error);
    }
}


function processPiece(piece) {
    const roomName = piece["form_pieces[type]"]?.trim();
    let roomNameCase = roomName.charAt(0).toUpperCase() + roomName.slice(1).toLowerCase();
    const liImageName = document.querySelector(`li[data-room-name="${roomNameCase}"]`);

    if (!liImageName) {
        console.warn(`No list item found for room name: ${roomName}`);
        return;
    }

    const imageName = liImageName.getAttribute('data-room-icon');
    const loadingDragonPiece = document.querySelector('.loader-page-piece');

    fetch(`/render-accordion?roomName=${encodeURIComponent(roomName)}&imageName=${encodeURIComponent(imageName)}&counterAccordion=${encodeURIComponent(counterAccordion)}&accordionCounter=${encodeURIComponent(accordionCounter)}`)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (loadingDragonPiece){
                loadingDragonPiece.style.display = "none";
            }
            accordionCounter++;
            const newAccordionItem = document.createElement('li');
            newAccordionItem.innerHTML = data.html;
            let form = newAccordionItem.firstElementChild;

            Object.keys(piece).forEach(key => {
                const input = form.querySelector(`[name="${key}"]`);
                if (input) {
                    input.value = piece[key];
                }
            });

            const separator = document.createElement('hr');
            separator.style.margin = "6px 0";
            simulhomeGroupAccordion.appendChild(newAccordionItem);
            simulhomeGroupAccordion.appendChild(separator);

            if (elementsPieceInput) countLiElementsInAccordion();
            simulhomeAddZone.classList.add('d-none');

            setupEventListenersForAccordionItem(newAccordionItem);
            newAccordionItem.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        })
        .catch(error => {
            console.error('Error fetching accordion item:', error);
        });
}

const scrollIntoViewWithOffset = (element, offset) => {
    window.scrollTo({
        behavior: 'smooth',
        inline: 'nearest',
        top:
            element.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
    })
}

// Main execution logic
if (roomItems) {
    roomItems.forEach(item => {
        item.addEventListener('click', function () {
            const roomName = item.getAttribute('data-room-name');
            const imageName = item.getAttribute('data-room-icon');
            counterAccordion++;
            fetch(`/render-accordion?roomName=${encodeURIComponent(roomName)}&imageName=${encodeURIComponent(imageName)}&counterAccordion=${encodeURIComponent(counterAccordion)}`)
                .then(response => response.json())
                .then(data => {
                    const newAccordionItem = document.createElement('li');
                    newAccordionItem.innerHTML = data.html;
                    const separator = document.createElement('hr');
                    separator.style.margin = "6px 0";
                    simulhomeGroupAccordion.appendChild(newAccordionItem);
                    simulhomeGroupAccordion.appendChild(separator);

                    if (elementsPieceInput) countLiElementsInAccordion();

                    simulhomeAddZone.classList.add('d-none');

                    setupEventListenersForAccordionItem(newAccordionItem);
                    scrollIntoViewWithOffset(newAccordionItem,60);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        });
    });
}


const btnPiece = document.getElementById("form_pieces_submit");
if (btnPiece) {
    btnPiece.addEventListener("click", function (e) {
        e.preventDefault();
        const forms = document.querySelectorAll('.form_pieces');
        const valueForm = [];
        let hasError = false;

        forms.forEach(function (form) {
            const formData = new FormData(form);
            const formValues = {};

            formData.forEach(function (value, key) {
                const input = form.querySelector(`[name="${key}"]`); // Get input element by name

                if (input && !input.classList.contains('pieceName') && !input.classList.contains('d-none')) {
                    const isEmpty = input.type === "select-one"
                        ? !input.value || input.selectedIndex === 0
                        : input.value.trim() === "";

                    if (input.id === 'form_pieces_surface') {
                        const parent = input.closest('.simulhome-inputText-calcul');
                        if (isEmpty && parent) {
                            parent.classList.add('error-postal'); // Add error to parent
                            hasError = true;
                            const accordionItem = input.closest('.accordion-item');
                            if (accordionItem) {
                                updateErrorCount(accordionItem); // Increment error count
                            }
                        } else if (parent) {
                            parent.classList.remove('error-postal'); // Remove error from parent
                            const accordionItem = input.closest('.accordion-item');
                            if (accordionItem) {
                                updateErrorCount(accordionItem); // Decrement error count
                            }
                        }
                    } else {
                        if (isEmpty) {
                            input.classList.add('error-postal');
                            hasError = true;

                            const accordionItem = input.closest('.accordion-item');
                            if (accordionItem) {
                                accordionItem.classList.add('error-postal');
                                updateErrorCount(accordionItem);
                            }
                        } else {
                            input.classList.remove('error-postal');

                            const accordionItem = input.closest('.accordion-item');
                            if (accordionItem) {
                                updateErrorCount(accordionItem);
                            }
                        }
                    }
                }

                formValues[key] = value;
            });

            valueForm.push(formValues);
        });

        const errorPostalLabel = document.querySelector('.error-postal');

        if (errorPostalLabel && hasError) {
            e.preventDefault();
            errorPostalLabel.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }

        if (!hasError) {
            fetch('/render-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(valueForm),
            })
                .then(response => response.json())
                .then(data => {
                    window.location.href = data.pathResult;
                })
                .catch(error => {
                    console.error('Erreur :', error);
                });
        }
    });

    document.addEventListener('input', function (e) {
        const input = e.target;

        if (input && !input.classList.contains('pieceName') && !input.classList.contains('d-none')) {
            const isEmpty = input.type === "select-one"
                ? !input.value || input.selectedIndex === 0
                : input.value.trim() === "";

            if (input.id === 'form_pieces_surface') {
                const parent = input.closest('.simulhome-inputText-calcul');
                const accordionItem = input.closest('.accordion-item');

                if (isEmpty && parent) {
                    parent.classList.add('error-postal');
                    if (accordionItem) updateErrorCount(accordionItem);
                } else if (parent) {
                    parent.classList.remove('error-postal');
                    if (accordionItem) updateErrorCount(accordionItem);
                }
            } else {
                if (isEmpty) {
                    input.classList.add('error-postal');

                    const accordionItem = input.closest('.accordion-item');
                    if (accordionItem) {
                        accordionItem.classList.add('error-postal'); // Add error to accordion
                        updateErrorCount(accordionItem); // Increment error count
                    }
                } else {
                    input.classList.remove('error-postal'); // Remove error class

                    const accordionItem = input.closest('.accordion-item');
                    if (accordionItem) {
                        updateErrorCount(accordionItem); // Decrement error count
                    }
                }
            }
        }
    });

    // Function to update the error count
    function updateErrorCount(accordionItem) {
        // Compter les erreurs (exclure les éléments masqués avec .d-none)
        const errorCount = accordionItem.querySelectorAll('.error-postal:not(.d-none)').length;
        const accordionInfo = accordionItem.querySelector('.simulhome-accordion-info');

        if (accordionInfo) {
            const errorText = errorCount === 1 ? "champ manquant" : "champs manquants";
            accordionInfo.textContent = `${errorCount} ${errorText}`;

            if (errorCount === 0) {
                accordionInfo.classList.add('d-none');
            } else {
                accordionInfo.classList.remove('d-none');
            }
        }

        if (errorCount === 0) {
            accordionItem.classList.remove('error-postal');
        } else {
            accordionItem.classList.add('error-postal');
        }
    }

}

const qualificationForm = document.querySelector('.qualificationForm');
if (qualificationForm){
    closePopinQualification();
    suggestionModalQualification();
    submitModalQualification();
}
function closePopinQualification() {
    const inputSearch = document.getElementById('input-search-distributor');
    const suggestionsContainer = document.getElementById('dropdown-suggestions');
    const qualificationFormWrapper = document.querySelector('.qualificationForm-wrapper');

    const closeButton = document.querySelector('.qualificationForm-close');

    if (!qualificationForm || !inputSearch || !suggestionsContainer) {
        console.error('Required elements not found in the DOM');
        return;
    }

    if (closeButton) {
        closeButton.addEventListener('click', function () {
            qualificationForm.classList.remove('is-visible');
        });
    }

    window.addEventListener('click', function (event) {
        if (!qualificationFormWrapper.contains(event.target) && qualificationForm.classList.contains('is-visible')) {
            qualificationForm.classList.remove('is-visible');
        }
    });

    qualificationFormWrapper.addEventListener('click', function (event) {
        event.stopPropagation(); // Prevent click event from bubbling up to the window
    });
}

function suggestionModalQualification(){
    const inputSearch = document.getElementById('input-search-distributor');
    const suggestionsContainer = document.getElementById('dropdown-suggestions');
    inputSearch.addEventListener('input', function () {
        const searchValue = inputSearch.value.trim();
        if (searchValue.length >= 2) {
            fetch('/suggestion-from-file', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({searchInput: searchValue})
            })
                .then(response => response.json())
                .then(data => {
                    const matchedData = data.matchedData;
                    suggestionsContainer.innerHTML = '';
                    if (matchedData.length > 0) {
                        suggestionsContainer.classList.remove('d-none');
                        matchedData.forEach(item => {
                            const suggestionItem = document.createElement('li');
                            suggestionItem.textContent = item["Raison sociale"];
                            suggestionItem.classList.add('suggestion-item');
                            suggestionItem.addEventListener('click', function () {
                                inputSearch.value = item["Raison sociale"];
                                inputSearch.dataset.idExterne = item["ID Externe"];
                                suggestionsContainer.removeChild(suggestionItem);
                                suggestionsContainer.innerHTML = '';

                                if (suggestionsContainer.children.length === 0) {
                                    suggestionsContainer.classList.add('d-none');
                                }
                            });
                            suggestionsContainer.appendChild(suggestionItem);
                        });
                    } else {
                        suggestionsContainer.classList.add('d-none');
                    }
                })
                .catch(error => {
                    console.error('Error fetching suggestions:', error);
                });
        } else {
            suggestionsContainer.innerHTML = '';
            suggestionsContainer.classList.add('d-none');
        }
    });
}

function submitModalQualification(){
    let submitPopinQualification = document.querySelector('.qualificationForm-valide');
    if (submitPopinQualification) {
        submitPopinQualification.addEventListener("click", function (e) {
            e.preventDefault();

            const form = document.querySelector('.qualificationForm-form');
            const formData = new FormData(form);
            const formValues = Object.fromEntries(formData.entries());
            let attributIdExterne = document.getElementById('input-search-distributor').getAttribute('data-id-externe');
            if (attributIdExterne) formValues['distributor'] = attributIdExterne;

            fetch('/qualification-form-submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues)
            })
                .then(response => response.json())
                .then(data => {
                    form.closest('.qualificationForm.is-visible').classList.remove('is-visible');
                    let dataToSend = {
                        qualification: true
                    };
                    maxxing.sendPostRequest(dataToSend);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }
}


const modalRadiatorLogin = document.querySelector(".radiator-popin-login .popup-connection");
if (modalRadiatorLogin){
    const reAddModal = () => {
        if (!document.body.contains(modalRadiatorLogin)) {
            document.body.appendChild(modalRadiatorLogin);
        }
    };

    const observer = new MutationObserver(() => reAddModal());
    observer.observe(document.body, { childList: true, subtree: true });
}

class MaxxingLib {
  constructor({assetBaseUrl, targetNode, apiToken, urlApi} = {}) {
    // Valeur par défaut pour assetBaseUrl
    this.assetBaseUrl = assetBaseUrl || '/bundles/thermorprosite/images/maxxingLib/';
    this.mobileAssetBaseUrl = this.assetBaseUrl + 'mobile/';
    this.$mobileMastersContainer = document.querySelector(".FullHeaderTop-item--horizonLogo-mobile");
    this.apiToken = apiToken || null; // Stocke le token
   // this.urlApi = urlApi || 'https://www.thermor-pro-ezplatform.ezp'; // url Api
    this.urlApi = urlApi || 'https://www.thermor-pro.fr'; // url Api

    // Définit la cible par défaut si aucun targetNode n'est fourni
    this.targetNode = targetNode || '.maxxingContainer';

    // Vérification et sélection basée sur le type de sélecteur
    if (this.targetNode.startsWith('#') || this.targetNode.startsWith('.')) {
      this.thermorMasterHeader = document.querySelectorAll(this.targetNode);
    } else {
      console.error('Le sélecteur fourni doit être une classe (".") ou un ID ("#").');
      this.thermorMasterHeader = [];
    }

    // Injection des éléments DOM dans chaque container trouvé
    if (this.thermorMasterHeader.length > 0) {
      // this.thermorMasterHeader.forEach((item) => {
        try {
          this.initData();  // Appel de la méthode pour initialiser les données
        } catch (error) {
          console.error('Erreur lors de l’initialisation du conteneur:', error);
        }
      // });
    } else {
      console.error('Aucun élément UI valide trouvé pour thermorMasterHeader.');
    }


  }


  // Méthode pour injecter les éléments DOM nécessaires
  injectDOMElements(container) {
    if (!container) throw new Error('Le conteneur est manquant');
    // Sélectionner le div  et Vérifier que le <a> existe déjà
    const flexContainer = container.querySelector('.maxxingContainer-wrapper');
    const linkElement = flexContainer.querySelector('.maxxingContainer-logo');

    // Insérer les nouveaux éléments après le lien <a> s'ils n'existent pas déjà
    if (linkElement) {
      // Vérifier si 'EngagementProgramHeader--status' n'existe pas déjà
      if (!flexContainer.querySelector('.EngagementProgramHeader--status')) {
        const statusButton = document.createElement('button');
        statusButton.className = 'EngagementProgramHeader--status';
        statusButton.innerHTML = `
        <p class="EngagementProgramHeader--statusName"></p>
        <span class="EngagementProgramHeader--statusLogo"></span>
      `;
        // Insérer après l'élément <a>
        linkElement.insertAdjacentElement('afterend', statusButton);
      }

      // Vérifier si 'EngagementProgramHeader--niveau' n'existe pas déjà
      if (!flexContainer.querySelector('.EngagementProgramHeader--niveau')) {
        const niveauDiv = document.createElement('div');
        niveauDiv.className = 'EngagementProgramHeader--niveau';
        niveauDiv.innerHTML = `
        <div class="EngagementProgramHeader--xp d-flex align-items-center">
          <label class="desktop-level"></label>
          <div class="progress-container">
            <div class="progress-level" id="progress-level"></div>
          </div>
          <label class="mob-level"></label>
        </div>
        <div class="EngagementProgramHeader--lingot" data-nextlevel="0">
          <span></span> <img src="${this.assetBaseUrl}union.svg">
        </div>
        <div class="cxp-maxxing" data-currentlevel="0"></div>
      `;
        // Insérer après le bouton de statut
        flexContainer.querySelector('.EngagementProgramHeader--status')
            .insertAdjacentElement('afterend', niveauDiv);
      }

      // Vérifier si 'EngagementProgramHeader--lango' n'existe pas déjà
      if (!flexContainer.querySelector('.EngagementProgramHeader--lango')) {
        const langoButton = document.createElement('button');
        langoButton.className = 'EngagementProgramHeader--lango';
        langoButton.innerHTML = `
        <p class="EngagementProgramHeader--langoName" style="min-width: 74px;"> <span></span>  <span>lingots</span></p>
        <span class="EngagementProgramHeader--langoLogo">
            <img src="${this.assetBaseUrl}lango.svg">
        </span>
        <div class="lingot-maxxing" data-lingot="0"></div>
      `;
        // Insérer après la div 'EngagementProgramHeader--niveau'
        flexContainer.querySelector('.EngagementProgramHeader--niveau')
            .insertAdjacentElement('afterend', langoButton);
      }
    }
    if (!container.querySelector('.infoBull--wrapper')) {
      const infoBullWrapper = document.createElement('div');
      infoBullWrapper.className = 'infoBull--wrapper';

      // Ajouter le contenu initial
      infoBullWrapper.innerHTML = `
      <div class="infoBull--logo"></div>
      <div class="infoBull--desc d-none">
          <p>Vous bénéficiez d'un super pouvoir.</p>
          <p>Chaque aventure rapporte</p>
          <div class="d-flex justify-content-center mt-2 gap-2">
              <div class="infoBull--xp d-flex gap-2">
                  <img alt="union" src="${this.assetBaseUrl}/union.svg" />
              </div>
              <div class="infoBull--lango d-flex gap-2">
                  <img  alt="lango" src="${this.assetBaseUrl}/lango.svg" />
              </div>
          </div>
      </div>
    `;
      container.appendChild(infoBullWrapper);
    }
    // Ajouter les gestionnaires d'événements après l'insertion des éléments
    const statusButtons = flexContainer.querySelectorAll('.EngagementProgramHeader--status');
    if (statusButtons.length > 0) {
      statusButtons.forEach((item) => {
        item.addEventListener('click', function () {
          const infoBullWrapper = container.querySelector('.infoBull--wrapper');
          if (infoBullWrapper) {
            infoBullWrapper.classList.toggle("show");
          }
        });
      });
    }
  }

  injectNotifeQueteStatutHTML(targetSelector) {
    const html = `
    <div class="notifeQueteStatut notifeQueteStatutJs" tabindex="-1" role="dialog" aria-labelledby="" data-from="fromjs" aria-hidden="false">
        <button class="notifeQueteStatut-close">&times;</button>
        <div class="image-container">
            <!-- Les images seront ajoutées dynamiquement -->
        </div>
        <div class="notifeQueteStatut-content">
            <p class="notifeQueteStatut-top-title">
             <span class="notifeQueteStatut-top-status"></span> thermor !</p>
            <p class="notifeQueteStatut-title"></p>
            <div class="notifeQueteStatut-buttom"></div>
        </div>
    </div>`;
    const targetElement = document.querySelector(targetSelector);
    const existingElement = targetElement.querySelector('.notifeQueteStatutJs');
    if (!existingElement) {
      // Sélectionner la cible et insérer le HTML à la fin
      if (targetElement) {
        targetElement.insertAdjacentHTML('beforeend', html);
      } else {
        console.error(`Élément cible non trouvé pour le sélecteur : ${targetSelector}`);
      }
    }


  }

  injectSubQueteHTML(targetSelector) {
    const htmlContent = `
        <div class="notifeSubQueteStatut notifeSubQueteStatutJS2" tabindex="-1" role="dialog" data-from="fromjs2"  aria-labelledby="" aria-hidden="false">
            <button class="notifeSubQueteStatut-close">&times;</button>
            <div>
                <img alt="clairon" src="${this.assetBaseUrl}clairon-ok.svg" class="notifeSubQueteStatut-top-logo"/>
            </div>
            <div class="notifeSubQueteStatut-content">
                <p class="notifeSubQueteStatut-top-title">
                  <span class="notifeSubQueteStatut-title-text"></span>
                  <span class="notifeSubQueteStatut-top-status"></span>
                </p>
                <p class="notifeSubQueteStatut-title"></p>
                <div class="notifeSubQueteStatut-buttom">
                    <div class="justify-content-center justify-content-sm-start">
                        <p class="notifeSubQueteStatut-gains"></p>
                    </div>
                    <a href="#" class="Link PrimaryButton PrimaryButton--transparent hidden d-sm-block"></a>
                </div>
            </div>
        </div>
    `;

    const targetElement = document.querySelector(targetSelector);
    const existingElement = targetElement.querySelector('.notifeSubQueteStatutJS2');

    if (!existingElement) {
      // Sélectionner la cible et insérer le HTML à la fin
      if (targetElement) {
        targetElement.insertAdjacentHTML('beforeend', htmlContent);
      } else {
        console.error(`Élément cible non trouvé pour le sélecteur : ${targetSelector}`);
      }
    }


  }

  updateNotifeQueteStatutHTML(targetSelector, variables, delay = 0) {
    const container = document.querySelector(`${targetSelector} .notifeQueteStatutJs`);
    if (!container) {
      console.error(`Aucune notification trouvée dans le sélecteur : ${targetSelector}`);
      return;
    }

    // Vérifier si la notification est visible
    if (container.getAttribute('aria-hidden') === 'false') {
      // Attendre le délai spécifié avant de mettre à jour
      setTimeout(() => {
        // Mise à jour de la classe
        container.className = `notifeQueteStatut notifeQueteStatutJs ${variables.class}`;
        container.setAttribute('aria-labelledby', variables.modalLabel || '');

        // Mise à jour de l'image
        const imageContainer = container.querySelector('.image-container');
        let imageHTML = `<img alt="QueteStatut" src="${variables.imgSrc}" class="notifeQueteStatut-top-logo"/>`;
        imageContainer.innerHTML = imageHTML;

        // Mise à jour du titre principal
        const topTitle = container.querySelector('.notifeQueteStatut-top-title');
        topTitle.innerHTML = variables.title || '';

        // Mise à jour du sous-titre
        const subtitle = container.querySelector('.notifeQueteStatut-title');
        subtitle.textContent = variables.subtitle || '';

        // Mise à jour des gains ou bonus
        const bottomContainer = container.querySelector('.notifeQueteStatut-buttom');
        bottomContainer.innerHTML = `
      <div class="justify-content-center justify-content-sm-start super-power" ${
            !(variables.infoMaxxing?.XP) ? 'style="display: none"' : ''
        }>
          ${variables.text ? `<p class="notifeQueteStatut-gains">${variables.text}</p>` : ''}
          ${
            variables.infoMaxxing?.XP
                ? `
              <div class="notifeQueteStatut-bonus">
                  <div class="notifeQueteStatut-item notifeQueteStatut-item-xp">
                      <div class="notifeQueteStatut-coef">
                          <span>+${variables.infoMaxxing.XP}%</span>
                      </div>
                      <img src="${this.assetBaseUrl}union.svg" alt="ico-xp">
                  </div>
                  <div class="notifeQueteStatut-item notifeQueteStatut-item-lingots">
                      <div class="notifeQueteStatut-coef">
                          <span>+${variables.infoMaxxing.LINGOTS}%</span>
                      </div>
                      <img src="${this.assetBaseUrl}lango.svg" alt="ico-lingots">
                  </div>
              </div>`
                : ''
        }
      </div>
      ${
            variables.hrefBtn && variables.textBtn
                ? `<a href="${variables.hrefBtn}" class="Link PrimaryButton PrimaryButton--transparent hidden d-sm-block">${variables.textBtn}</a>`
                : ''
        }
      `;

        // Ajouter des écouteurs pour la fermeture et le défilement
        const closeButton = container.querySelector('.notifeQueteStatut-close');
        if (closeButton) {
          closeButton.addEventListener('click', function () {
            container.setAttribute('aria-hidden', 'false');
          });
        }

        // Gestion du défilement
        const handleScroll = () => {
          if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            container.classList.add("scrolled");
          } else {
            container.classList.remove("scrolled");
          }
        };

        // Ajouter l'écouteur de défilement
        window.addEventListener('scroll', handleScroll);

        // Masquer la notification après mise à jour
        container.setAttribute('aria-hidden', 'true');
      }, delay);
    }
  }

  updateSubQueteContent(data) {
    const notificationElement = document.querySelector('.notifeSubQueteStatutJS2');
    if (!notificationElement) {
      console.warn('Notification element not found.');
      return;
    }

    // Mise à jour des classes et attributs
    if (data.class) notificationElement.className = `notifeSubQueteStatut notifeSubQueteStatutJS2 ${data.class}`;
    if (data.modalLabel) notificationElement.setAttribute('aria-labelledby', data.modalLabel);

    // Mise à jour des contenus
    if (data.title) notificationElement.querySelector('.notifeSubQueteStatut-title-text').textContent = data.title;
    if (data.subtitle) notificationElement.querySelector('.notifeSubQueteStatut-title').textContent = data.subtitle;
    if (data.text) notificationElement.querySelector('.notifeSubQueteStatut-gains').textContent = data.text;

    // Mise à jour du bouton
    const buttonElement = notificationElement.querySelector('a.PrimaryButton');
    if (buttonElement && data.textBtn) {
      buttonElement.textContent = data.textBtn;
      buttonElement.href = data.hrefBtn || '#';
    }
    // Ajouter des écouteurs pour la fermeture et le défilement
    const closeButton = notificationElement.querySelector('.notifeSubQueteStatut-close');
    if (closeButton) {
      closeButton.addEventListener('click', function () {
        notificationElement.setAttribute('aria-hidden', 'false');
      });
    }
    // Gestion du défilement
    const handleScroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        notificationElement.classList.add("scrolled");
      } else {
        notificationElement.classList.remove("scrolled");
      }
    };

    // Ajouter l'écouteur de défilement
    window.addEventListener('scroll', handleScroll);

    notificationElement.setAttribute('aria-hidden', 'true');
  }

  injectBadgeHTML(targetSelector) {
    const htmlContent = `
        <div class="NotifeBadge">
                <div>
                    <p class="NotifeBadge-title"></p>
                    <p class="NotifeBadge-message"></p>
                </div>
                <div class="NotifeBadge-visuel">
                  <img src="" alt="NotifeBadge-visuel"/>
                  <div class="scintillement" >
                    <img src="/bundles/thermorprosite/icons/Scintillement1.png" class="scintillement-img1" alt="scintillement"/>
                    <img src="/bundles/thermorprosite/icons/Scintillement2.png" class="scintillement-img2" alt="scintillement"/>
                    <img src="/bundles/thermorprosite/icons/Scintillement3.png" class="scintillement-img3" alt="scintillement"/>
                    <img src="/bundles/thermorprosite/icons/Scintillement4.png" class="scintillement-img4" alt="scintillement"/>
                  </div>
                </div>
            </div>
    `;

    const targetElement = document.querySelector(targetSelector);
    const existingElement = targetElement.querySelector('.NotifeBadge');

    if (!existingElement) {
      // Sélectionner la cible et insérer le HTML à la fin
      if (targetElement) {
        targetElement.insertAdjacentHTML('beforeend', htmlContent);
      } else {
        console.error(`Élément cible non trouvé pour le sélecteur : ${targetSelector}`);
      }
    }


  }

  updateBadgeContent(data) {
    const BadgenotificationElement = document.querySelector('.NotifeBadge');
    if (!BadgenotificationElement) {
      console.warn('Notification element not found.');
      return;
    }

    // Mise à jour des contenus
    if (data.title) BadgenotificationElement.querySelector('.NotifeBadge-title').textContent = data.title;
    if (data.img) {
      BadgenotificationElement.querySelector('.NotifeBadge-visuel img').setAttribute('src', data.img);
      BadgenotificationElement.querySelector('.NotifeBadge-visuel img').setAttribute('style', 'max-width:100px;');

    }
    if (data.message) BadgenotificationElement.querySelector('.NotifeBadge-message').textContent = data.message;

    BadgenotificationElement.setAttribute('style', 'display:block;');
  }

  // Méthode générique pour récupérer les données
  fetchData() {
    const apiUrl = `${this.urlApi}/info-maxxing`; // Construction de l'URL
    return fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization-Maxxing': `Token ${this.apiToken}`
      },
     
    })
        .then(response => response.json())
        .catch(error => {
          console.error('Erreur lors de la récupération des données:', error);
          throw error; // Lancer l'erreur pour la gérer dans la méthode appelante
        });
  }

  injectLoaders(wrapper) {
    const loader = document.createElement('div');
    loader.className = 'load-wrapp';
    loader.innerHTML = `<div class="load-2"><div class="line"></div><div class="line"></div><div class="line"></div></div>`;
    wrapper.appendChild(loader);
    wrapper.appendChild(loader);
    wrapper.appendChild(loader);
  }

  removeLoaders(item) {
    var loaders = item.querySelectorAll('.maxxingContainer-wrapper .load-wrapp');
    if (loaders.length > 0) {
      loaders.forEach(function (loader) {
        loader.remove();
      });
    }
  }

  initBoutique() {
    let equipementLingot = document.querySelector('.programEquipementPage--lingotBlock .lingot-count');
    if (equipementLingot) {
      const lingot = document.querySelector('.EngagementProgramHeader--lango .EngagementProgramHeader--langoName span');
      if (lingot) {
        equipementLingot.innerHTML = "<div class=\"lingot-count\">Je dispose de\n" +
            "        <span>" + lingot.innerText + "</span>\n" +
            "        <img src=\"/bundles/thermorprosite/icons/lango.svg\"></div>"
        let orderPopins = document.querySelectorAll('.orderPopin');
        if (orderPopins.length > 0) {
          orderPopins.forEach(function (order) {
            let orderValue = order.querySelector('.input-product').value;
            var productData = JSON.parse(orderValue);
            let lingotRemain = lingot.innerText - productData.lingotProduct;
            let orderLingotRemain = order.querySelector('.lingot-sold');
            orderLingotRemain.innerHTML = lingotRemain;
          });
        }
      }
    }
  }

  initData() {
    this.thermorMasterHeader.forEach((item) => {
      if (item.classList.contains('FullHeaderTop-item--horizonLogo-mobile')) { //if mobile bar inject loader before fetch
        this.injectLoaders(item.querySelector('.maxxingContainer-wrapper'));
      }
    });

    this.fetchData()
        .then(data => {
          this.thermorMasterHeader.forEach((item) => {
            this.injectDOMElements(item);  // Appel d'une méthode pour injecter les éléments DOM
            // Récupération des éléments DOM, évitant de répéter les requêtes
            const nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
            const nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
            const counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
            const statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
            const progressLevels = item.querySelector('.progress-level');
            const lingot = item.querySelector('.EngagementProgramHeader--lango .EngagementProgramHeader--langoName span');
            const logoStatusMaxxingContainer = item.querySelector('.EngagementProgramHeader--statusLogo');
            let infoBullLogoContainer = item.querySelector('.infoBull--logo');
            const infoBullDesc = item.querySelector('.infoBull--desc ');
            const infoBullXp = item.querySelector('.infoBull--desc .infoBull--xp');
            const infoBullLingot = item.querySelector('.infoBull--desc .infoBull--lango');

            // Vérifier si toutes les variables DOM requises existent
            if (nv && nvMb && counterValue && statusMaxxing && progressLevels && lingot && logoStatusMaxxingContainer && infoBullLogoContainer && infoBullDesc && infoBullXp && infoBullLingot) {
              // Initialisation Level
              this.initLevel(nv, nvMb, progressLevels, data);

              // Initialisation Lingots
              this.InitLingot(lingot, data);

              // Initialisation XP
              this.initXP(counterValue, data);

              // Initialisation Statut
              this.initStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, data, infoBullDesc);
              if (item.classList.contains('FullHeaderTop-item--horizonLogo-mobile')) {
                // Si c'est une barre mobile, supprimer les loaders après le fetch et ajouter l'effet de défilement
                this.removeLoaders(item);

                let lastScrollY = window.scrollY;
                window.addEventListener('scroll', () => {
                  const currentScrollY = window.scrollY;

                  if (currentScrollY > lastScrollY) {
                    item.classList.add('hidden-bar');
                  } else {
                    item.classList.remove('hidden-bar');
                  }

                  lastScrollY = currentScrollY;
                });
              }

            } else {
              console.warn("Un ou plusieurs éléments DOM sont manquants, initialisation ignorée pour cet élément.");
            }
          });
          this.initBoutique();
        }).catch(error => {
      console.error('Erreur lors de la récupération des données :', error);
      throw error;
    });

  }

  initLevel(nv, nvMb, progressLevels, data) {
    try {
      // Vérification et conversion des données pour éviter les erreurs
      const xp = isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP);
      const nextLevel = isNaN(parseFloat(data.C_NEXTLEVEL)) ? 0 : parseFloat(data.C_NEXTLEVEL);
      const niveau = isNaN(parseInt(data.NIVEAU)) ? 0 : parseInt(data.NIVEAU);

      // Calcul du nouveau niveau de la barre de progression
      const newNiveauBar = nextLevel === 0 ? 0 : Math.floor((xp / (xp + nextLevel)) * 100);

      // Vérification de l'existence des éléments DOM avant la mise à jour
      if (nv) {
        nv.textContent = "Niveau " + niveau;
        nv.setAttribute('data-level', niveau);
      } else {
        console.error("L'élément nv est invalide ou non défini.");
      }

      if (nvMb) {
        nvMb.textContent = "Niveau " + niveau;
      } else {
        console.error("L'élément nvMb est invalide ou non défini.");
      }

      // Mise à jour de la barre de progression
      if (progressLevels) {
        this.updateProgressBar(progressLevels, newNiveauBar);
      } else {
        console.error("L'élément progressLevels est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation du niveau :", error);
    }
  }

  updateLevel(dom, domMob, newLavel, progressLevels, newNiveauBar) {
    if (dom) {
      dom.textContent = "Niveau " + newLavel;
      dom.setAttribute('data-level', newLavel);
    } else {
      console.error("L'élément nv est invalide ou non défini.");
    }

    if (domMob) {
      domMob.textContent = "Niveau " + newLavel;
    } else {
      console.error("L'élément nvMb est invalide ou non défini.");
    }
    // Mise à jour de la barre de progression
    if (progressLevels) {
      this.updateProgressBar(progressLevels, newNiveauBar);
    } else {
      console.error("L'élément progressLevels est invalide ou non défini.");
    }

  }

  InitLingot(lingot, data) {
    try {
      // Vérification de la validité de la donnée C_LINGOT et assignation d'une valeur par défaut si nécessaire
      const lingotValue = isNaN(parseFloat(data.C_LINGOT)) ? 0 : Math.round(parseFloat(data.C_LINGOT));

      // Mise à jour du textContent et de l'attribut data-lingot si l'élément lingot existe
      if (lingot) {
        lingot.textContent = lingotValue;
        lingot.setAttribute('data-lingot', lingotValue);
      } else {
        console.error("L'élément lingot est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation du lingot :", error);
    }
  }

  updateLingot(dom, newLingot) {
    try {
      // Vérification de la validité de la donnée C_LINGOT et assignation d'une valeur par défaut si nécessaire
      const lingotValue = isNaN(parseFloat(newLingot)) ? 0 : Math.round(parseFloat(newLingot));

      // Mise à jour du textContent et de l'attribut data-lingot si l'élément lingot existe
      if (dom) {
        dom.textContent = lingotValue;
        dom.setAttribute('data-lingot', lingotValue);
      } else {
        console.error("L'élément lingot est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation du lingot :", error);
    }
  }

  initXP(counterValue, data) {
    try {
      // Vérification de la validité des données et assignation de valeurs par défaut si nécessaire
      const xp = isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP);
      const nextLevel = isNaN(parseFloat(data.C_NEXTLEVEL)) ? 0 : parseFloat(data.C_NEXTLEVEL);
      const sumNextLevel = Math.round(xp + nextLevel);

      // Mise à jour du contenu et des attributs si counterValue existe
      if (counterValue) {
        counterValue.innerHTML = Math.round(xp) + '/' + sumNextLevel;
        counterValue.setAttribute('data-xp', Math.round(xp));
        counterValue.setAttribute('data-sumnextlavel', sumNextLevel);
      } else {
        console.error("L'élément counterValue est invalide ou non défini.");
      }
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation de XP :", error);
    }
  }

  updateXP(dom, newXp, newNextLevel) {
    const xp = Number(newXp);
    const nextLevel = Number(newNextLevel);
    const sumNextLevel = Math.round(xp + nextLevel);
    if (dom) {
      dom.innerHTML = Math.round(xp) + '/' + Math.round(sumNextLevel);
      dom.setAttribute('data-xp', Math.round(xp));
      dom.setAttribute('data-sumnextlavel', sumNextLevel);
    } else {
      console.error("L'élément counterValue est invalide ou non défini.");
    }
  }

  initStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, data, infoBullDesc) {
    try {
      // Vérification que data.STATUT est valide
      const status = data.STATUT ? data.STATUT.toLowerCase() : 'inconnu';

      // Mise à jour du texte du statut, avec première lettre en majuscule, et ajout de l'attribut data-status
      if (statusMaxxing) {
        statusMaxxing.textContent = status.charAt(0).toUpperCase() + status.slice(1);
        statusMaxxing.setAttribute('data-status', status.toUpperCase());  // Ajout de l'attribut data-status
      } else {
        console.error("L'élément statusMaxxing est invalide ou non défini.");
      }

      // Préparation du chemin de base pour le logo de statut
      const baseLogoSrc = this.assetBaseUrl || '';  // Utilisation d'une valeur par défaut si assetBaseUrl n'est pas défini
      const isMobile = logoStatusMaxxingContainer.closest('.maxxingContainer')?.classList.contains('FullHeaderTop-item--horizonLogo-mobile');
      // Création et insertion du logo pour statusMaxxing
      if (logoStatusMaxxingContainer) {
        const logoStatusMaxxing = document.createElement('img');
        logoStatusMaxxing.id = 'logo-maxxing';  // Ajout de l'ID
        if (isMobile) {
          logoStatusMaxxing.src = (this.mobileAssetBaseUrl) + status + '.png';  // Source de l'image
        } else {
          logoStatusMaxxing.src = baseLogoSrc + status + '.png';  // Source de l'image
        }
        logoStatusMaxxing.alt = 'Status logo';  // Texte alternatif

        // Vider le conteneur avant d'insérer un nouveau logo pour éviter les doublons
        logoStatusMaxxingContainer.innerHTML = '';
        logoStatusMaxxingContainer.appendChild(logoStatusMaxxing);
      } else {
        console.error("L'élément logoStatusMaxxingContainer est invalide ou non défini.");
      }

      // Création et insertion du logo pour infoBullLogo
      if (infoBullLogoContainer) {
        const infoBullLogo = document.createElement('img');
        infoBullLogo.src = baseLogoSrc + status + 'Logo.png';  // Source de l'image
        infoBullLogo.alt = 'Info Bull Logo';  // Texte alternatif

        // Vider le conteneur avant d'insérer un nouveau logo pour éviter les doublons
        infoBullLogoContainer.innerHTML = '';
        infoBullLogoContainer.appendChild(infoBullLogo);
      } else {
        console.error("L'élément infoBullLogoContainer est invalide ou non défini.");
      }

      if (data.SUPERPOUVOIR && typeof data.SUPERPOUVOIR === 'object' && Object.keys(data.SUPERPOUVOIR).length > 0) {
        infoBullDesc.classList.remove('d-none');
        // Mise à jour ou ajout du span pour la partie XP
        if (infoBullXp && data.SUPERPOUVOIR.XP) {
          let spanXp = infoBullXp.querySelector('span');
          if (spanXp) {
            spanXp.innerHTML = `+ ${data.SUPERPOUVOIR.XP}%`;
          } else {
            infoBullXp.innerHTML = `<span>+ ${data.SUPERPOUVOIR.XP}%</span>` + infoBullXp.innerHTML;
          }
        }

        // Mise à jour ou ajout du span pour la partie Lingot
        if (infoBullLingot && data.SUPERPOUVOIR.LINGOTS) {
          let spanLingot = infoBullLingot.querySelector('span');
          if (spanLingot) {
            spanLingot.innerHTML = `+ ${data.SUPERPOUVOIR.LINGOTS}%`;
          } else {
            infoBullLingot.innerHTML = `<span>+ ${data.SUPERPOUVOIR.LINGOTS}%</span>` + infoBullLingot.innerHTML;
          }
        }
      } else {
        console.log("Aucune donnée dans SUPERPOUVOIR, aucun changement dans infoBull--desc.");
      }

    } catch (error) {
      console.error("Une erreur s'est produite dans initStatus :", error);
    }
  }

  updateStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, status, xp, lingots, infoBullDesc) {
    try {
      // Mise à jour du texte du statut et de l'attribut data-status
      if (statusMaxxing) {
        const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        statusMaxxing.textContent = formattedStatus;
        statusMaxxing.setAttribute('data-status', status.toUpperCase());
      } else {
        console.error("L'élément statusMaxxing est invalide ou non défini.");
      }

      // Mise à jour du logo du statut dans logoStatusMaxxingContainer
      if (logoStatusMaxxingContainer) {
        const logoStatusMaxxing = logoStatusMaxxingContainer.querySelector('img');
        const isMobile = logoStatusMaxxingContainer.closest('.maxxingContainer')?.classList.contains('FullHeaderTop-item--horizonLogo-mobile') || false;
        const baseLogoSrc = this.assetBaseUrl || '';

        if (logoStatusMaxxing) {
          if (isMobile) {
            logoStatusMaxxing.src = (this.mobileAssetBaseUrl) + status.toLowerCase() + '.png';  // Source de l'image
          } else {
            logoStatusMaxxing.src = baseLogoSrc + status.toLowerCase() + '.png';  // Source de l'image
          }

          logoStatusMaxxing.alt = 'Status logo';
        } else {
          console.error("Le logo de statut n'a pas été trouvé dans logoStatusMaxxingContainer.");
        }
      } else {
        console.error("L'élément logoStatusMaxxingContainer est invalide ou non défini.");
      }

      // Mise à jour du logo dans infoBullLogoContainer
      if (infoBullLogoContainer) {
        const infoBullLogo = infoBullLogoContainer.querySelector('img');
        if (infoBullLogo) {
          infoBullLogo.src = `${this.assetBaseUrl || ''}${status.toLowerCase()}Logo.png`;
          infoBullLogo.alt = 'Info Bull Logo';
        } else {
          console.error("Le logo d'info bull n'a pas été trouvé dans infoBullLogoContainer.");
        }
      } else {
        console.error("L'élément infoBullLogoContainer est invalide ou non défini.");
      }

      // Mise à jour des informations XP si elles sont fournies
      if (infoBullXp && xp !== undefined && xp !== 0) {
        infoBullDesc.classList.remove('d-none');
        const xpSpan = infoBullXp.querySelector('span');
        if (xpSpan) {
          xpSpan.textContent = `+ ${xp}%`;
        } else {
          infoBullXp.innerHTML = `<span>+ ${xp}%</span>` + infoBullXp.innerHTML;
        }
      } else if (!infoBullXp) {
        console.error("L'élément infoBullXp est invalide ou non défini.");
      }

      // Mise à jour des informations Lingots si elles sont fournies
      if (infoBullLingot && lingots !== undefined && lingots !== 0) {
        infoBullDesc.classList.remove('d-none');
        const lingotSpan = infoBullLingot.querySelector('span');
        if (lingotSpan) {
          lingotSpan.textContent = `+ ${lingots}%`;
        } else {
          infoBullLingot.innerHTML = `<span>+ ${lingots}%</span>` + infoBullLingot.innerHTML;
        }
      } else if (!infoBullLingot) {
        console.error("L'élément infoBullLingot est invalide ou non défini.");
      }

    } catch (error) {
      console.error("Une erreur s'est produite dans updateStatusNotification :", error);
    }
  }

  updateProgressBar(progressLevel, niveauBar) {
    if (niveauBar > 99) {
      progressLevel.style.width = "0%";
    } else {
      progressLevel.style.width = niveauBar.toString() + "%";
    }
  }

//++++++++++++++++++++++++++++++++++++++++++++

  showLevelUpNotification(txtNewLevel, newLevel, dom) {
    this.$mobileMastersContainer.classList.remove('hidden-bar'); //show bar on masters reward
    dom.innerHTML += `
                        <div class="EngagementProgramHeader--redbg"></div>
                        <div class="EngagementProgramHeader--wrapper nextLevel">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="${this.assetBaseUrl}stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="${this.assetBaseUrl}stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="${this.assetBaseUrl}stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="${this.assetBaseUrl}stars4.svg" />
                            </div>
                            <p>${txtNewLevel} ${newLevel} !</p>
                        </div>
                     `
  };

  showStatusNotification(currentStatut, newStatut, dom) {
    this.$mobileMastersContainer.classList.remove('hidden-bar'); //show bar on masters reward
    dom.innerHTML = `
            <div class="EngagementProgramHeader--redbg"></div>
            <div class="EngagementProgramHeader--wrapper">
                <div class="EngagementProgramHeader--scintillement">
                    <img class="EngagementProgramHeader--scintillement-img1" src="${this.assetBaseUrl}stars1.svg" />
                    <img class="EngagementProgramHeader--scintillement-img2" src="${this.assetBaseUrl}stars2.svg" />
                    <img class="EngagementProgramHeader--scintillement-img3" src="${this.assetBaseUrl}stars3.svg" />
                </div>
                <div class="EngagementProgramHeader--text-animation">
                    <span class="currentStatus"> ${currentStatut} </span>
                    <span class="nextStatus"> ${newStatut} </span>
                </div>
            </div>`;
  };

  showLingotRewardNotification = (clingotReward, newLingot, currentLingot, dom) => {
    this.$mobileMastersContainer.classList.remove('hidden-bar'); //show bar on masters reward
    dom.innerHTML += `
                     <div class="EngagementProgramHeader--redbg"></div>
                     <div class="EngagementProgramHeader--wrapper">
                         <span class="EngagementProgramHeader--langoLogo-animation">
                            <img src="${this.assetBaseUrl}lingoWhite.svg" />
                         </span>
                          <div class="EngagementProgramHeader--scintillement">
                              <img class="EngagementProgramHeader--scintillement-img1" src="${this.assetBaseUrl}stars1.svg" />
                              <img class="EngagementProgramHeader--scintillement-img2" src="${this.assetBaseUrl}stars2.svg" />
                              <img class="EngagementProgramHeader--scintillement-img3" src="${this.assetBaseUrl}stars3.svg" />
                              <img class="EngagementProgramHeader--scintillement-img4" src="${this.assetBaseUrl}stars4.svg" />
                          </div>
                          <div class="EngagementProgramHeader--level-coefficient">
                              <span class="lango-coefficient"> ${clingotReward.reward ? clingotReward.reward.toFixed(2) : (newLingot - currentLingot)} </span>
                          </div>
                        </div>`;
  };

  showLingotGainNotification = (clingotReward, dom) => {
    this.$mobileMastersContainer.classList.remove('hidden-bar'); //show bar on masters reward
    dom.innerHTML = `
      <div class="EngagementProgramHeader--redbg"></div>
      <div class="EngagementProgramHeader--wrapper">
      <span class="EngagementProgramHeader--langoLogo-animation">
        <img src="${this.assetBaseUrl}lingoWhite.svg" />
      </span>
          <div class="EngagementProgramHeader--scintillement">
            <img class="EngagementProgramHeader--scintillement-img1" src="${this.assetBaseUrl}stars1.svg" />
            <img class="EngagementProgramHeader--scintillement-img2" src="${this.assetBaseUrl}stars2.svg" />
            <img class="EngagementProgramHeader--scintillement-img3" src="${this.assetBaseUrl}stars3.svg" />
            <img class="EngagementProgramHeader--scintillement-img4" src="${this.assetBaseUrl}stars4.svg" />
          </div>
        <div class="EngagementProgramHeader--level-coefficient">
         <span class="lango-coefficient"> ${clingotReward.bonus ? clingotReward.bonus.toFixed(2) : 0} </span>
    </div>
  </div>`;
  };

  showXPCoefficientNotification = (cxp_rewardReward, gainCXPPartieEntiereToShow, dom) => {
    this.$mobileMastersContainer.classList.remove('hidden-bar'); //show bar on masters reward
    dom.innerHTML = `
            <div class="EngagementProgramHeader--redbg"></div>
            <div class="EngagementProgramHeader--wrapper">
                            <div class="EngagementProgramHeader--scintillement level">
                                <img class="EngagementProgramHeader--scintillement-img1" src="${this.assetBaseUrl}stars1.svg" />
                                <img class="EngagementProgramHeader--scintillement-img2" src="${this.assetBaseUrl}stars2.svg" />
                                <img class="EngagementProgramHeader--scintillement-img3" src="${this.assetBaseUrl}stars3.svg" />
                                <img class="EngagementProgramHeader--scintillement-img4" src="${this.assetBaseUrl}stars4.svg" />
                            </div>
                                <div class="EngagementProgramHeader--level-coefficient">
                                    <div class="blurred-overlay"></div>
                                        <span class="level-coefficient">
                                            ${cxp_rewardReward ? cxp_rewardReward.toFixed(2) : gainCXPPartieEntiereToShow}
                                        </span>
                                    </div>
                        </div>`;
  };

  //++++++++++++++++++++++++++++++++++++++++++++


  processApiData(cxpValue, data, cxp_reward, clingot_reward) {
    this.fetchData()
        .then(data => {
          try {
            const delay = 4000;
            // Sélection de tous les éléments avec la classe maxxingContainer

            const maxxingContainer = document.querySelectorAll('.maxxingContainer');
            if (maxxingContainer.length === 0) {
              console.error("L'élément .maxxingContainer est introuvable.");
              return;
            }

            // Nouvelles valeurs de l'API

            const newXp = cxpValue === 0 ? (isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP)) : cxpValue;
            const newNextLevel = isNaN(parseFloat(data.C_NEXTLEVEL)) ? 0 : parseFloat(data.C_NEXTLEVEL);
            const newLingot = isNaN(parseFloat(data.C_LINGOT)) ? 0 : parseFloat(data.C_LINGOT);
            const newStatus = data.STATUT || 'inconnu';
            const newLevel = isNaN(parseFloat(data.NIVEAU)) ? 0 : parseFloat(data.NIVEAU);
            const newSuperpowerXp = (data.SUPERPOUVOIR && !isNaN(parseFloat(data.SUPERPOUVOIR.XP))) ? parseFloat(data.SUPERPOUVOIR.XP) : 0;
            const newSuperpowerLingots = (data.SUPERPOUVOIR && !isNaN(parseFloat(data.SUPERPOUVOIR.LINGOTS))) ? parseFloat(data.SUPERPOUVOIR.LINGOTS) : 0;


            ////////////////////////////////////////////////


            this.thermorMasterHeader.forEach((item) => {
              // Récupérer les anciennes valeurs

              const currentXp = parseFloat(item.querySelector('.EngagementProgramHeader--lingot span')?.getAttribute('data-xp')) || 0;
              const currentLingot = parseFloat(item.querySelector('.EngagementProgramHeader--langoName span')?.getAttribute('data-lingot')) || 0;
              const currentStatus = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName')?.getAttribute('data-status') || '0';
              const currentLevel = parseFloat(item.querySelector('.EngagementProgramHeader--niveau .desktop-level')?.getAttribute('data-level')) || 0;

              // Calcul du nouveau niveau de la barre de progression
              const newNiveauBar = newNextLevel === 0 ? 0 : Math.floor((newXp / (newXp + newNextLevel)) * 100);
              // Calcul le gain XP
              const gainXp = parseFloat(newXp - currentXp).toFixed(2);


              // Récupération des éléments DOM, évitant de répéter les requêtes
              const nv = item.querySelector('.EngagementProgramHeader--niveau .desktop-level');
              const nvMb = item.querySelector('.EngagementProgramHeader--niveau .mob-level');
              const cxpMaxxing = item.querySelector('.EngagementProgramHeader--niveau .cxp-maxxing')
              const counterValue = item.querySelector('.EngagementProgramHeader--lingot span');
              const statusMaxxing = item.querySelector('.EngagementProgramHeader--status .EngagementProgramHeader--statusName');
              const progressLevels = item.querySelector('.progress-level');
              const lingotWrapper = item.querySelector('.EngagementProgramHeader--lango .lingot-maxxing');
              const lingot = item.querySelector('.EngagementProgramHeader--lango .EngagementProgramHeader--langoName span');
              const logoStatusMaxxingContainer = item.querySelector('.EngagementProgramHeader--statusLogo');
              const infoBullLogoContainer = item.querySelector('.infoBull--logo');
              const infoBullXp = item.querySelector('.infoBull--desc .infoBull--xp');
              const infoBullDesc = item.querySelector('.infoBull--desc ');
              const infoBullLingot = item.querySelector('.infoBull--desc .infoBull--lango');

              // popin
              let notifStatusLingots = document.querySelector('.notifeQueteStatut-item-lingots .notifeQueteStatut-coef')?.firstElementChild;
              let notifStatusXp = document.querySelector('.notifeQueteStatut-item-xp .notifeQueteStatut-coef')?.firstElementChild;


              this.updateXP(counterValue, newXp, newNextLevel);
              this.updateLingot(lingot, newLingot)
              this.updateLevel(nv, nvMb, newLevel, progressLevels, newNiveauBar)
              this.updateStatus(statusMaxxing, infoBullLogoContainer, infoBullXp, infoBullLingot, logoStatusMaxxingContainer, newStatus, newSuperpowerXp, newSuperpowerLingots, infoBullDesc)

              /* ****************** LingotMaxxing ************************** */


              if (parseFloat(currentLingot) !== parseFloat(newLingot)) {
                this.showLingotRewardNotification(clingot_reward, newLingot, currentLingot, lingotWrapper)

                if (clingot_reward.bonus) {
                  setTimeout(() => {
                    this.showLingotGainNotification(clingot_reward, lingotWrapper)
                  }, delay);
                }
              }


              /* ************************** levelMaxxing  */


              let passNewLevel = item.classList.contains('FullHeaderTop-item--horizonLogo-mobile') ? "Bravo, niveau" : "Vous avez atteint le niveau";

              if (parseFloat(currentLevel) !== newLevel) {
                cxpMaxxing.innerHTML = ""
                this.showLevelUpNotification(passNewLevel, newLevel, cxpMaxxing)
              }

              /* **************************  StatusMaxxing   */

              if (currentStatus !== newStatus) {

                cxpMaxxing.innerHTML = ""
                this.showStatusNotification(currentStatus, newStatus, cxpMaxxing)


                notifStatusLingots.innerHTML = `${newSuperpowerLingots}%`;
                notifStatusXp.innerHTML = `${newSuperpowerXp}%`;

                document.querySelector('.notifeQueteStatut-item-xp').closest('.notifeQueteStatut-bonus').closest('.super-power').style.display = 'block';

                const notificationVariables = {
                  class: "status",
                  modalLabel: "status",
                  title: `Bravo ! vous êtes désormais ${newStatus} thermor !`,
                  subtitle: "Vous bénéficiez d'un super pouvoir.",
                  text: "Chaque aventure rapporte",
                  textBtn: "Voir mon profil",
                  hrefBtn: "/masters/profil",
                  imgSrc: `/bundles/thermorprosite/images/maxxingLib/${newStatus.toLowerCase()}Logo.png`,
                  infoMaxxing: typeof infoMaxxing !== 'undefined' ? infoMaxxing : {
                    LINGOTS: `${newSuperpowerLingots}`,
                    XP: `${newSuperpowerXp}`
                  }
                };
                this.injectNotifeQueteStatutHTML('body')
                this.updateNotifeQueteStatutHTML('body', notificationVariables, 5000);


                if ((newSuperpowerLingots === 0 || newSuperpowerXp === 0) || (newSuperpowerLingots === '' || newSuperpowerXp === '')) {
                  document.querySelector('.notifeQueteStatut.status').classList.add('zeroBonus');
                  document.querySelector('.infoBull--desc').style.display = 'none';
                }

              }


              /* ************************** xpMaxxing   */

              if (currentXp !== newXp && gainXp !== 0) {
                const gainCXPPartieEntiere = Math.floor(gainXp);
                const gainCXPPartieEntiereToShow = gainCXPPartieEntiere === 0 ? "0.00" : gainCXPPartieEntiere.toFixed(2);
                let gainCXPPartieDecimale = parseFloat(gainXp - gainCXPPartieEntiere).toFixed(2);
                gainCXPPartieDecimale = Math.max(0, parseFloat(gainCXPPartieDecimale));

                cxpMaxxing.innerHTML = ""
                this.showXPCoefficientNotification(cxp_reward.reward, gainCXPPartieEntiereToShow, cxpMaxxing)
                if (cxp_reward.bonus || gainCXPPartieDecimale > 0) {
                  setTimeout(() => {
                    this.showXPCoefficientNotification(cxp_reward.bonus, gainCXPPartieDecimale, cxpMaxxing)
                  }, delay);
                }
              }

            })


            ///////////////////////////////////////////////


          } catch (error) {
            console.error("Une erreur s'est produite dans apiMaxxing :", error);
          }
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des données de l'API :", error);
        });
  }

  apiMaxxing(cxpValueOrData, cxp_reward = {}, clingot_reward = {}) {
    let cxpValue, data;

    // Vérification si le premier argument est un nombre ou un objet
    if (typeof cxpValueOrData === 'number' || typeof cxpValueOrData === 'string') {
      cxpValue = cxpValueOrData;
      // Appel de l'API pour récupérer les données
      this.fetchData()
          .then(fetchedData => {
            data = fetchedData;
            this.processApiData(cxpValue, data, cxp_reward, clingot_reward);
          })
          .catch(error => {
            console.error("Erreur lors de la récupération des données de l'API :", error);
          });
    } else if (typeof cxpValueOrData === 'object') {
      // Si un objet est fourni, on l'utilise comme données
      data = cxpValueOrData;
      cxpValue = isNaN(parseFloat(data.C_XP)) ? 0 : parseFloat(data.C_XP); // Utilisation de C_XP de l'objet data
      this.processApiData(cxpValue, data, cxp_reward, clingot_reward);
    } else {
      console.error("Le premier argument doit être soit un nombre, soit un objet de données.");
    }
  }

  sendPostRequest(dataToSend) {
    if (this.thermorMasterHeader.length > 0) {
      const url = `${this.urlApi}/info-doc`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization-Maxxing': `Token ${this.apiToken}`
        },
        body: JSON.stringify(dataToSend),
       
      })
          .then(response => response.json())
          .then(data => {
            if (data.webTransaction_request_response_code &&
                data.webTransaction_request_response_code.ticket_msg &&
                data.webTransaction_request_response_code.ticket_msg.value &&
                data.webTransaction_request_response_code.ticket_msg.value != "") {
              let targets = data.webTransaction_request_response_code.ticket_msg.value.split(/\r?\n/);
              this.apiTarget(targets)
            }else {
              console.warn('ticket message ou ses propriétés sont manquantes dans la réponse.');
            }
            if (data.webTransaction_request_response_code && data.webTransaction_request_response_code.rewards) {
              let rewards = data.webTransaction_request_response_code.rewards;
              if (rewards) {
                let counters = data.webTransaction_request_response_code.counters.counter;
                let totalXpRewards = counters.filter((counter) => counter.name == "C_CUMULXP");
                let totalLingotsRewards = counters.filter((counter) => counter.name == "C_LINGOT");
                let cxpRewards = rewards.filter((reward) => reward.counter_name == "C_XP");
                let clingotRewards = rewards.filter((reward) => reward.counter_name == "C_LINGOT");
                let xpReward = this.extractRewards(totalXpRewards, cxpRewards);
                let lingotRewards = this.extractRewards(totalLingotsRewards, clingotRewards);

                if (xpReward.reward > 0 || lingotRewards.reward > 0) {
                  this.apiMaxxing(data, xpReward, lingotRewards);
                }
              }
            } else {
              console.warn('La propriété rewards est manquante ou non définie dans la réponse.');
            }
          })
          .catch(error => {
            console.error(error);
          });
    }
  }

  extractRewards(totalValue, data) {
    let reward = 0;
    let bonus = 0;
    let total = 0;

    const amounts = data
        .filter(function (obj) {
          for (var i = 0; i < obj.information.length; i++) {
            if (obj.information[i].name === "DISPLAY_BENEFITS") {
              return Boolean(obj.information[i].value);
            }
          }
          return false;
        })
        .map(obj => parseFloat(obj.amount));

    if (totalValue.length > 0) {
      total = parseFloat(totalValue[0].value) - parseFloat(totalValue[0].value_before);
      if (amounts.length > 0) {
        bonus = Math.min(...amounts);
      }
      reward = total - bonus
    }

    return {reward: reward, bonus: bonus};
  }

  apiTarget(targets) {
    let notifeQueteModalTitle = document.querySelector('.notifeQueteStatut.quete .notifeQueteStatut-title');
    let notifeSubQueteModalTitle = document.querySelector('.notifeSubQueteStatut.subquete .notifeSubQueteStatut-title');
    const selfThis = this;
    let dataToSend = {
      codes: targets
    };
    fetch(`${this.urlApi}/info-target`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization-Maxxing': `Token ${this.apiToken}`
      },
      body: JSON.stringify(dataToSend),
      mode: 'no-cors'
    })
        .then(response => response.json())
        .then(data => {
          let trophies = data.TROPHIES;
          let subquests = data.SUBQUESTS;
          let quests = data.QUESTS;
          for (const [key, value] of Object.entries(trophies)) {


            this.injectBadgeHTML('main')
            const badgeVariables = {
              title: "NOUVEAU BADGE ! ",
              img: value.IMAGE_ON_TEXT,
              message: "Vous venez de remporter le badge \"" + value.NAME + "\"",
            }
            this.updateBadgeContent(badgeVariables)
          }
          for (const [key, value] of Object.entries(subquests)) {
            notifeSubQueteModalTitle.textContent = value.NAME + " - " + value.description;
            // notifeSubQueteModal.setAttribute('aria-hidden', 'true');
            const subqueteVariables = {
              class: "subquete",
              modalLabel: "subquest",
              title: `Bravo ! Vous avez accompli une sous-quête !`,
              subtitle: value.NAME + " - " + value.description,
              text: "Accomplissez les autres missions de la quête pour recevoir une récompense",
              textBtn: "Voir mon profil",
              hrefBtn: "/masters/profil"
            };
            selfThis.injectSubQueteHTML('body')
            selfThis.updateSubQueteContent(subqueteVariables);

          }

          for (const [key, value] of Object.entries(quests)) {
            if (value.NAME) {
              notifeQueteModalTitle.textContent = value.NAME;

              const notificationVariables = {
                class: "quete",
                modalLabel: "quest",
                title: `Bravo ! Vous avez accompli votre quête !`,
                subtitle: value.NAME,
                text: "Vous remportez",
                textBtn: "Voir mon profil",
                hrefBtn: "/masters/profil",
                imgSrc:`/bundles/thermorprosite/images/maxxingLib/clairon-ok.svg`,
              };
              selfThis.injectNotifeQueteStatutHTML('body')
              selfThis.updateNotifeQueteStatutHTML('body', notificationVariables, 5000);
              //notifeQueteModal.setAttribute('aria-hidden', 'true');
            }
          }
        })
  }
}

window.MaxxingLib = MaxxingLib;










